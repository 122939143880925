<template>
  <VaDataTable v-model:sort-by="params.sort_by" v-model:sorting-order="params.sort" :items="users" :columns="columns" :loading="loading">
    <template #cell(name)="{ rowData }">
      <div class="flex items-center gap-2 max-w-[230px] ellipsis">{{ rowData.first_name }} {{ rowData.last_name }}</div>
    </template>

    <template #cell(email)="{ rowData }">
      <div class="flex items-center gap-2 max-w-[230px] ellipsis">
        {{ rowData.email }}
      </div>
    </template>

    <template #cell(phone)="{ rowData }">
      <div class="flex items-center gap-2 max-w-[230px] ellipsis">
        {{ rowData.phone }}
      </div>
    </template>

    <template #cell(is_verified)="{ rowData }">
      <div class="flex items-center gap-2 max-w-[230px] ellipsis">
        {{ rowData.is_verified ? 'Verified' : '' }}
        <VaButton
          v-if="!rowData.is_verified"
          class="gap-4"
          :icon="'material-icons-fact_check'"
          preset="secondary"
          size="small"
          @click="handleVerify(rowData as User)"
        >
          Verify</VaButton
        >
      </div>
    </template>

    <template #cell(actions)="{ rowData }">
      <div class="flex gap-2">
        <VaButton preset="primary" size="small" icon="mso-info" aria-label="Go to user page" @click="goUserPage(rowData as User)" />
        <VaButton
          preset="primary"
          size="small"
          icon="mso-passkey"
          aria-label="User detail page"
          tooltip="Reset Password"
          @click="handlePasswordReset(rowData as User)"
        />
      </div>
    </template>
  </VaDataTable>

  <div class="flex flex-col-reverse md:flex-row gap-2 justify-between items-center py-2">
    <div>
      <b>{{ pagination.total }} results.</b>
      Results per page
      <VaSelect v-model="params.limit" class="!w-20" :options="[10, 50, 100]" />
    </div>

    <div v-if="pagination.total_pages" class="flex">
      <VaButton preset="secondary" icon="va-arrow-left" aria-label="Previous page" @click="prevPage" />
      <VaButton class="mr-2" preset="secondary" icon="va-arrow-right" aria-label="Next page" @click="nextPage" />
      <VaPagination
        v-model="params.page"
        buttons-preset="secondary"
        :pages="pagination.total_pages"
        :visible-pages="5"
        :boundary-links="false"
        :direction-links="false"
      />
    </div>
  </div>

  <VaModal v-model="passwordModalVisible" size="small" mobile-fullscreen close-button hide-default-actions>
    <ResetPasswordModal :selected-user="selectedUser" @close="() => (passwordModalVisible = false)" />
  </VaModal>
</template>
<script setup lang="ts">
import { defineProps, defineEmits, PropType, ref } from 'vue'
import { VaDataTable, useModal } from 'vuestic-ui'
import { User } from '../composables/typeUsers'
import ResetPasswordModal from '../../user/modals/ResetPasswordModal.vue'
import { Pagination } from '../../../../data/typePagination'

const { confirm, init } = useModal()

const passwordModalVisible = ref(false)
const selectedUser = ref({} as User)

const props = defineProps({
  users: {
    type: Array as PropType<User[]>,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  pagination: {
    type: Object as PropType<Pagination>,
    required: true,
  },
  params: {
    type: Object as any,
    required: true,
  },
})

const emit = defineEmits<{
  (event: 'verify-user', params: User): void
  (event: 'detail-user', params: User): void
}>()

const columns: Array<{ label: string; key: string; sortable?: boolean; align?: 'left' | 'center' | 'right' }> = [
  { label: 'Name', key: 'name', sortable: true, align: 'right' },
  { label: 'E-Mail', key: 'email', sortable: true, align: 'right' },
  { label: 'Phone', key: 'phone', sortable: true, align: 'right' },
  { label: 'E-Mail Verification', key: 'is_verified', sortable: true, align: 'right' },
  { label: 'Actions', key: 'actions', sortable: false, align: 'right' },
]

const goUserPage = (user: User) => {
  emit('detail-user', user)
}

const handleVerify = async (user: User) => {
  try {
    const agreed = await confirm({
      title: 'Verify User',
      message: `Are you sure you want to verifying this user ${user.email}?`,
      okText: 'Verify',
      cancelText: 'Cancel',
      size: 'small',
      maxWidth: '380px',
    })
    if (agreed) {
      emit('verify-user', user)
    }
  } catch (error) {
    console.error('caz yaptı', error)
    init({
      message: `Unexcepted error.`,
    })
  }
}

const handlePasswordReset = async (user: User) => {
  selectedUser.value = user
  passwordModalVisible.value = true
}

const prevPage = () => {
  if (props.params.page > 1) {
    props.params.page--
  }
}

const nextPage = () => {
  if (props.params.page < props.pagination.total_pages) {
    props.params.page++
  }
}
</script>

<style lang="scss" scoped>
.va-data-table {
  ::v-deep(.va-data-table__table-tr) {
    border-bottom: 1px solid var(--va-background-border);
  }
}
</style>
