<template>
  <div class="flex flex-col md:flex-row gap-2 h-full">
    <LicenseList
      :user-licenses="mappedUserLicenses"
      :selected-tab="selectedTab"
      :selected-user-license="selectedUserLicense"
      @selectLicense="handleSelectLicense"
      @changeTab="selectedTab = $event"
    />

    <div class="flex flex-col w-full gap-2 md:w-3/5 h-full">
      <LicenseDetails
        :selected-user-license="selectedUserLicense"
        :selected-license-addons="selectedLicenseAddons"
        @closeLicenseDetails="selectedUserLicense = {} as UserLicenseModel"
      />
      <AgentSubAccountTabs :selected-user-license="selectedUserLicense" :selected-user="selectedUser" />
    </div>

    <ActionPanel
      :selected-user="selectedUser"
      :selected-user-license="selectedUserLicense"
      @createLicense="createModalVisible = true"
      @removeLicense="handleRemoveUserLicense"
      @verifyUser="handleVerify"
      @resetPassword="passwordModalVisible = true"
    />
  </div>

  <VaModal v-model="createModalVisible" size="small" mobile-fullscreen close-button hide-default-actions>
    <ModalCreateUserLicense :user="selectedUser" :visible="createModalVisible" @close="() => (createModalVisible = false)" @save="handleCreateUserLicense" />
  </VaModal>

  <VaModal v-model="editModalVisible" size="small" mobile-fullscreen close-button hide-default-actions>
    <ModalEditUserLicense
      :selected-user-license="selectedUserLicense"
      :visible="editModalVisible"
      @close="() => (editModalVisible = false)"
      @save="handleEditUserLicense"
    />
  </VaModal>

  <VaModal v-model="passwordModalVisible" size="small" mobile-fullscreen close-button hide-default-actions>
    <ResetPasswordModal :selected-user="selectedUser" @close="() => (passwordModalVisible = false)" />
  </VaModal>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useModal, useToast } from 'vuestic-ui'
import { User } from '../../users/composables/typeUsers'
import { UserLicense, UserLicenseModel, UserLicenseType } from '../composables/typeUserLicense'
import { useLicenses } from '../../../licenses/composables/useLicenses'
import { useAddons } from '../../../license-addons/composables/useAddons'
import { useUserLicenses } from '../composables/useUserLicenses'
import { useUsers } from '../../users/composables/useUsers'
import LicenseDetails from './LicenseDetails.vue'
import AgentSubAccountTabs from './AgentSubAccountTabs.vue'
import ActionPanel from './ActionPanel.vue'
import ModalCreateUserLicense from '../modals/ModalCreateUserLicense.vue'
import ModalEditUserLicense from '../modals/ModalEditUserLicense.vue'
import ResetPasswordModal from '../modals/ResetPasswordModal.vue'
import LicenseList from './LicenseList.vue'

const props = defineProps<{
  selectedUser: User
}>()

const { confirm, init } = useModal()
const { init: notify } = useToast()

const { fetchUserLicenses, createUserLicense, deleteUserLicense } = useUserLicenses()
const { licenses, fetchLicenses } = useLicenses()
const { addons, fetchAddons } = useAddons()
const { verifyUser } = useUsers()

const createModalVisible = ref(false)
const passwordModalVisible = ref(false)
const editModalVisible = ref(false)
const userLicenses = ref<UserLicense[]>([])
const mappedUserLicenses = ref<UserLicenseModel[]>([])
const selectedUserLicense = ref<UserLicenseModel>({} as UserLicenseModel)
const selectedTab = ref<number>(0)

const selectedLicenseAddons = computed(() => {
  if (selectedUserLicense.value?.parentLicense?.addon_ids) {
    return addons.value.filter((addon) => selectedUserLicense.value?.parentLicense.addon_ids.includes(addon._id))
  }
  return []
})

const handleCreateUserLicense = async (newUserLicense: UserLicenseModel) => {
  try {
    console.log(newUserLicense)
    await createUserLicense(newUserLicense.userLicense)
    notify({
      message: `Created a user license.`,
      color: 'success',
    })
    await refreshUserLicenses()
  } catch (error: any) {
    notify({
      message: error.response?.data?.error ?? `Error: ${error.message}`,
      color: 'danger',
    })
  }
}

const handleRemoveUserLicense = async (userLicense: UserLicenseModel) => {
  if (userLicense.userLicense.is_deleted) {
    init({
      title: 'This license is already deleted',
      message: `${userLicense.parentLicense.name} license is already deleted.`,
      okText: 'OK',
      cancelText: '',
      size: 'small',
      maxWidth: '380px',
    })
    return
  }

  const agreed = await confirm({
    title: 'Delete license',
    message: `Are you sure you want to delete ${userLicense.parentLicense.name}?`,
    okText: 'Delete',
    cancelText: 'Cancel',
    size: 'small',
    maxWidth: '380px',
  })

  if (agreed) {
    try {
      await deleteUserLicense(userLicense.userLicense)
      notify({
        message: `Deleted a user license.`,
        color: 'success',
      })
      await refreshUserLicenses()
    } catch (error: any) {
      notify({
        message: error.response?.data?.error ?? `Error: ${error.message}`,
        color: 'danger',
      })
    }
  }
}

const handleEditUserLicense = async (userLicense: UserLicenseModel) => {
  console.log(userLicense)
  // Implement edit logic here
}

const handleSelectLicense = (uLicense: UserLicenseModel) => {
  if (!uLicense.userLicense.is_deleted) {
    selectedUserLicense.value = uLicense
  }
}

const handleVerify = async (user: User) => {
  const agreed = await confirm({
    title: 'Verify User',
    message: `Are you sure you want to verify this user ${user.email}?`,
    okText: 'Verify',
    cancelText: 'Cancel',
    size: 'small',
    maxWidth: '380px',
  })
  if (agreed) {
    try {
      await verifyUser(user)
      notify({
        message: `${user.email} has been verified.`,
        color: 'success',
      })
    } catch (error: any) {
      notify({
        message: `Error: ${error.message}`,
        color: 'danger',
      })
    }
  }
}

const refreshUserLicenses = async () => {
  if (props.selectedUser.id) {
    userLicenses.value = (await fetchUserLicenses(props.selectedUser.id, { limit: 9999 })) as UserLicense[]
    await mapUserLicenses()
  }
}

const mapUserLicenses = async () => {
  if (userLicenses.value && userLicenses.value.length > 0) {
    mappedUserLicenses.value = userLicenses.value
      .map((uLicense) => {
        const uLicenseType = uLicense.addon_ids && uLicense.addon_ids.length > 0 ? UserLicenseType.Addon : UserLicenseType.License

        if (uLicenseType == UserLicenseType.License) {
          const matchingLicense = licenses.value.find((license) => license._id == uLicense.license_id)
          if (matchingLicense) {
            return {
              parentLicense: matchingLicense,
              userLicense: uLicense,
              type: UserLicenseType.License,
            } as UserLicenseModel
          }
        } else {
          const matchingAddons = addons.value.find((addon) => uLicense.addon_ids?.includes(addon._id))
          if (matchingAddons) {
            return {
              parentAddon: matchingAddons,
              userLicense: uLicense,
              type: UserLicenseType.Addon,
            } as UserLicenseModel
          }
        }
      })
      .filter(Boolean) as UserLicenseModel[]
  }
}

watch(
  () => props.selectedUser,
  async (newVal, oldVal) => {
    if (newVal !== oldVal) {
      try {
        await refreshUserLicenses()
        licenses.value = await fetchLicenses({ limit: 9999 })
        addons.value = await fetchAddons({ limit: 9999 })
      } catch (error) {
        console.error('Error fetching data:', error)
        // Handle error (e.g., show notification, redirect)
      }
    }
  },
  { immediate: true },
)
</script>
