<template>
  <VaForm v-slot="{ isValid }" ref="create-user-license-form" class="flex-col justify-start items-start gap-4 inline-flex w-full">
    <div class="flex gap-4 flex-col sm:flex-row w-full">
      <VaSelect
        v-model="licensesIdsSelect"
        track-by="value"
        label="Licenses"
        class="w-full sm:w-1/2"
        :options="licenseOptions"
        name="licenses"
        searchable
        highlight-matched-text
        :rules="[validators.required]"
      />

      <VaSelect
        v-model="addonsIdsSelect"
        track-by="value"
        label="Addons"
        class="w-full sm:w-1/2"
        :options="addonsOptions"
        multiple
        name="addons"
        searchable
        highlight-matched-text
      />
    </div>
    <div class="flex gap-2 flex-col-reverse items-stretch justify-end w-full sm:flex-row sm:items-center">
      <VaButton preset="secondary" color="secondary" @click="$emit('close')">Cancel</VaButton>
      <VaButton :disabled="!isValid" @click="() => isValid && $emit('save', newUserLicense)">Add</VaButton>
    </div>
  </VaForm>
</template>

<script setup lang="ts">
import { onMounted, PropType, reactive, ref, watch } from 'vue'
import { VaButton, VaForm } from 'vuestic-ui'
import { validators } from '../../../../services/utils'
import { useLicenses } from '../../../licenses/composables/useLicenses'
import { useAddons } from '../../../license-addons/composables/useAddons'
import { User } from '../../users/composables/typeUsers'

const { fetchLicenses, licenses } = useLicenses()
const { fetchAddons, addons } = useAddons()

const licensesIdsSelect = ref([])
const addonsIdsSelect = ref([])

const licenseOptions = ref<any>([])
const addonsOptions = ref<any>([])

const props = defineProps({
  user: {
    type: Object as PropType<User>,
    required: true,
  },
})

onMounted(async () => {
  addons.value = await fetchAddons({ limit: 9999 })
  addonsOptions.value = addons.value.map((addon) => ({ text: addon.name, value: addon._id }))

  licenses.value = await fetchLicenses({ limit: 9999 })
  licenseOptions.value = licenses.value.map((license) => ({ text: license.name, value: license._id }))
})

watch(licensesIdsSelect, (newVal: any) => {
  newUserLicense.license_id = newVal.value
})

watch(addonsIdsSelect, (newVal) => {
  newUserLicense.addon_ids = newVal.map((item: any) => (item.value ? item.value : item)) as []
})

const newUserLicense = reactive({
  user_id: props.user.id,
  license_id: [],
  addon_ids: [],
})
</script>
