<template>
  <div class="flex flex-auto w-full pb-4">
    <h1 class="font-bold text-regularLarge">Reset Password</h1>
  </div>
  <VaForm v-slot="{ isValid }" ref="reset-password-email-form" class="flex-col justify-start items-start gap-4 inline-flex w-full">
    <div>
      <p class="text-wrap text-sm">A password reset email will be sent to the selected email address. Are you sure?</p>
    </div>
    <div class="flex gap-4 flex-col sm:flex-row w-full">
      <VaInput v-model="selectedUser.email" class="w-full sm:w-full" :rules="[validators.required, validators.email]" name="userEmail" readonly />
    </div>
    <div class="flex gap-2 flex-col-reverse items-stretch justify-end w-full sm:flex-row sm:items-center">
      <VaButton preset="secondary" color="secondary" @click="$emit('close')">Cancel</VaButton>
      <VaButton :disabled="!isValid" @click="() => isValid && onSave()">Send</VaButton>
    </div>
  </VaForm>
</template>

<script lang="ts" setup>
import { PropType } from 'vue'
import { validators } from '../../../../services/utils'
import { User } from '../../users/composables/typeUsers'
import { useToast } from 'vuestic-ui'
import { isAxiosError } from 'axios'
import { useUsers } from '../../users/composables/useUsers'

const { init: notify } = useToast()

const props = defineProps({
  selectedUser: {
    type: Object as PropType<User>,
    required: true,
  },
})

const onSave = async () => {
  try {
    await useUsers().resetPassword(props.selectedUser)

    notify({
      message: `Success`,
      color: 'success',
    })
  } catch (error: any) {
    if (isAxiosError(error)) {
      notify({
        message: `Unexcepted error on resetting password. Error: ${error.message}`,
        color: 'danger',
      })
    }
  }
}
</script>
