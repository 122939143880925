import axios, { AxiosError, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import config from './config'

const axiosInstance = axios.create({
  baseURL: config.ApiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
  timeout: 10000, // 10 saniye zaman aşımı
})

const LOGIN_PATH = '/auth/login'
const PUBLIC_PATHS = [LOGIN_PATH, '/auth/register', '/auth/forgot-password']

const isPublicPath = (path: string) => PUBLIC_PATHS.some((publicPath) => path.startsWith(publicPath))

let isRefreshing = false
let failedQueue: any[] = []

const processQueue = (error: AxiosError | null, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })
  failedQueue = []
}

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as InternalAxiosRequestConfig & { _retry?: boolean }

    if (error.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token
            return axiosInstance(originalRequest)
          })
          .catch((err) => Promise.reject(err))
      }

      originalRequest._retry = true
      isRefreshing = true

      try {
        const currentPath = window.location.pathname
        if (!isPublicPath(currentPath)) {
          window.location.href = `${LOGIN_PATH}?nextUrl=${encodeURIComponent(currentPath)}&status=expired`
          return Promise.reject(error)
        }
      } catch (refreshError) {
        processQueue(refreshError as AxiosError, null)
        return Promise.reject(refreshError)
      } finally {
        isRefreshing = false
      }
    }

    return Promise.reject(error)
  },
)

export default axiosInstance
