import { Addon } from '../../../license-addons/composables/typeAddons'
import { License } from '../../../licenses/composables/typeLicenses'

export type UserLicense = {
  _id: string
  user_id: string
  license_id: string
  used_agent_limit: number
  used_sub_account_limit: number
  agents: string[]
  sub_accounts: string[]
  license_snapshot: any | null
  addon_ids?: string[]
  addon_snapshots: any | null
  start_date: string
  end_date: string
  created_at: string
  updated_at: string
  updated_by: string
  deleted_at: string
  deleted_by: string
  created_by: string
  is_deleted: boolean
}

export enum UserLicenseType {
  License,
  Addon,
}

export type UserLicenseModel = {
  type: UserLicenseType
  parentLicense: License
  parentAddon: Addon
  userLicense: UserLicense
}
