export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'material-icons-group',
      },
    },
    {
      name: 'agents',
      displayName: 'menu.agents',
      meta: {
        icon: 'vuestic-iconset-components',
      },
    },
    {
      name: 'dynamic-scripts',
      displayName: 'menu.dynamic-scripts',
      meta: {
        icon: 'mso-terminal',
      },
    },
    {
      name: 'licenses',
      displayName: 'menu.licenses',
      meta: {
        icon: 'mso-clarify',
      },
      children: [
        {
          name: 'licenses',
          displayName: 'menu.licenses',
        },
        {
          name: 'licenses/addons',
          displayName: 'menu.addons',
        },
        {
          name: 'licenses/keys',
          displayName: 'menu.keys',
        },
        {
          name: 'licenses/vouchers',
          displayName: 'menu.vouchers',
        },
      ],
    },
    {
      name: 'monitic-settings',
      displayName: 'menu.monitic-settings',
      meta: {
        icon: 'vuestic-iconset-settings',
      },
    },
  ] as INavigationRoute[],
}
