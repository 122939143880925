<template>
  <VaForm v-slot="{ isValid }" ref="create-script-form" class="w-full">
    <div class="h-full w-full flex flex-col lg:flex-row md:flex-row">
      <div class="w-full lg:w-1/3 md:w-1/3 bg-white rounded-lg shadow-sm border border-gray-200 mr-4 p-4 overflow-y-auto max-h-[80vh]">
        <h2 class="text-lg font-semibold mb-3">Create Script</h2>
        <div class="flex flex-col gap-4 mb-4">
          <VaInput
            v-model="newScript.name"
            label="Script Name"
            required-mark
            class="w-full"
            :rules="[validators.required, validators.noSpecialChars]"
            name="scriptName"
          />
          <VaTextarea v-model="newScript.description" label="Description" required-mark class="w-full" :rules="[validators.required]" name="description" />
        </div>

        <div>
          <h3 class="text-lg font-semibold mb-2">Parameters</h3>
          <div v-for="(param, index) in newScript.parameters" :key="index" class="bg-white rounded-lg shadow-sm p-4 mb-2 border border-gray-200 space-y-4 text-xs">
            <VaForm v-slot="{ isValid: validParams }" class="flex flex-col gap-2">
              <div class="flex flex-col gap-2">
                <VaInput
                  id="paramName"
                  v-model="param.name"
                  label="Parameter Name"
                  required-mark
                  placeholder="Parameter Name"
                  size="small"
                  class="w-full"
                  :rules="[validators.required, validators.noSpecialChars]"
                />
              </div>
              <div class="flex flex-col gap-2">
                <VaSelect
                  id="paramType"
                  v-model="param.type"
                  label="Type"
                  :options="['string']"
                  required-mark
                  placeholder="Type"
                  size="small"
                  class="w-full"
                  :rules="[validators.required]"
                />
              </div>
              <div class="flex flex-col gap-2">
                <VaInput id="paramDefault" v-model="param.default" label="Default Value" placeholder="Default Value" size="small" class="w-full" />
              </div>
              <div class="flex flex-col gap-2">
                <VaTextarea
                  id="paramDesc"
                  v-model="param.description"
                  label="Description"
                  placeholder="Description"
                  required-mark
                  size="small"
                  class="w-full"
                  :rules="[validators.required]"
                />
              </div>
              <div class="mt-2">
                <VaCheckbox v-model="param.required" label="Is Required" size="small" class="text-sm" />
              </div>
              <div class="w-full flex flex-col justify-center gap-2">
                <VaButton :disabled="!validParams" color="info" size="small" class="w-full" @click="() => validParams && addParameterToScript(param.name)"
                  >Insert</VaButton
                >
                <VaButton color="danger" size="small" class="w-full" @click="removeParameter(index)">Remove</VaButton>
              </div>
            </VaForm>
          </div>
          <button class="text-blue-500 hover:underline mt-4 text-sm" @click="addParameter">Add New Parameter</button>
        </div>
      </div>

      <div class="flex-1 bg-white rounded-lg shadow-sm border p-4 border-gray-200 overflow-hidden overflow-y-auto max-h-[80vh]">
        <div class="flex flex-row gap-2">
          <h3 class="text-lg font-semibold mb-2 w-1/3">Script Editor</h3>
          <div class="flex flex-row justify-end w-2/3 items-center gap-2 pb-2">
            <VaSelect v-model="newScript.os" class="w-1/2" :options="['windows']" />
            <VaSwitch v-model="newScript.enabled" label="Enabled" size="small" />
          </div>
        </div>
        <div class="bg-[#1e1e1e] p-2 h-full max-h-[90%]">
          <MonacoEditor ref="insertHandle" v-model="newScript.script" language="powershell" :options="editorOptions" />
        </div>
      </div>
    </div>

    <div class="flex flex-col-reverse items-stretch justify-end w-full gap-2 sm:flex-row sm:items-center mt-4">
      <VaButton preset="secondary" color="secondary" @click="$emit('close')"> Cancel </VaButton>
      <VaButton :disabled="!isValid" @click="() => isValid && onSave()"> Save </VaButton>
    </div>
  </VaForm>
</template>

<script setup lang="ts">
import { reactive, ref } from 'vue'
import { VaForm, VaInput, VaButton, VaCheckbox } from 'vuestic-ui'
import { Script } from '../composables/typeScripts'
import { validators } from '../../../services/utils'
import MonacoEditor from '../../../components/monaco/MonacoEditor.vue'

interface MonacoEditorInstance {
  handleClick: (params: string) => void
}

const insertHandle = ref<MonacoEditorInstance | null>(null)

const emit = defineEmits<{
  (event: 'close'): void
  (event: 'save', script: Script): void
}>()

const newScript = reactive<Script>({
  name: '',
  description: '',
  script: '',
  parameters: [],
  enabled: true,
  os: 'windows',
})

const editorOptions = {
  fontSize: 12,
  minimap: { enabled: false },
  lineNumbers: 'on',
  automaticLayout: true,
  theme: 'vs-dark',
  language: 'powershell',
}

const addParameter = () => {
  newScript.parameters.push({
    name: '',
    description: '',
    type: 'string',
    required: false,
    default: '',
  })
}

const addParameterToScript = (param: string) => {
  if (insertHandle.value) {
    insertHandle.value.handleClick(param)
  }
}

const removeParameter = (index: number) => {
  newScript.parameters.splice(index, 1)
}

const onSave = async () => {
  try {
    emit('save', newScript)
  } catch (error) {
    console.error(error)
  }
}
</script>
