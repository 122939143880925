import { defineStore } from 'pinia'
import { User } from '../pages/users/users/composables/typeUsers'
import axiosInstance from '../services/interceptor'
import config from '../services/config'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      user: {} as User,
    }
  },

  actions: {
    changeUser(user: User) {
      this.user = user
    },
    async getUserProfile() {
      try {
        const response = await axiosInstance.get(`${config.LoginApi}/profile`)
        this.changeUser(response.data)
      } catch (error) {
        throw error
      }
    },
    clearUser() {
      this.user = {} as User
    },
  },
})
