<template>
  <ul class="space-y-2 w-full" :class="!excludeResultModal ? 'bg-white rounded-lg shadow-sm border border-gray-200 p-2' : ''">
    <li v-for="(result, index) in results" :key="index" @click="handleSelectResult(result)">
      <div
        class="flex items-center justify-between gap-2 bg-white ellipsis p-2 cursor-pointer rounded-lg shadow-sm border border-gray-200 hover:bg-gray-100 transition-colors"
        :class="selectedExecutionResult?.agent._id == result.agent._id ? '!bg-slate-200' : ''"
      >
        <div :class="getStatusColor(result)" class="w-3 h-3 rounded-full flex items-center justify-center pe-2"></div>

        <div class="flex justify-start w-full ellipsis">
          <div class="flex flex-col items-start gap-1 ellipsis">
            <div class="flex flex-row justify-center gap-2 sm:flex-col sm:gap-0 text-bold">
              {{ result.agent.basic_data.hostname }}
              <p class="text text-gray-600 text-xs text-wrap font-thin">
                {{ result.agent.name }}
              </p>
            </div>
            <p class="font-thin text-xs text-wrap">
              {{
                result.response && result.response.task
                  ? !result.response.task.is_done && result.response.task.is_ok && result.status
                    ? 'Yürütme sırasında bir sorun oluştu. Belki zaman aşımı'
                    : result.response.task.is_done && !result.response.task.is_ok && result.status
                      ? 'Yürütme sırasında bir sorun oluştu. Yanıtı kontrol edin'
                      : !result.response.task.is_done && !result.response.task.is_ok && result.status
                        ? 'Yürütme sırasında bir sorun oluştu. Yanıtı kontrol edin'
                        : result.response.message
                  : 'Görev bilgisi mevcut değil'
              }}
            </p>
          </div>
        </div>
        <div v-if="result.status && excludeResultModal" class="flex justify-end">
          <VaButton preset="primary" size="small" icon="mso-order_play" aria-label="Edit script" @click="handleShowResult(result)" />
        </div>
      </div>
    </li>
  </ul>

  <VaModal v-model="isExecuteModalVisible" size="large" hide-default-actions>
    <ResultsShowModal
      :results="results"
      :selected-script="selectedScript"
      :selected-execution-result="selectedExecutionResult"
      @close="isExecuteModalVisible = false"
    />
  </VaModal>
</template>

<script lang="ts" setup>
import { PropType, ref } from 'vue'
import { ExecutionStatus } from '../composables/typeExecutionStatus'
import { getStatusColor } from '../../../services/utils'
import ResultsShowModal from '../modals/ResultsShowModal.vue'
import { Script } from '../composables/typeScripts'

const selectedExecutionResult = ref<ExecutionStatus>()
const isExecuteModalVisible = ref(false)

defineProps({
  results: {
    type: Array as PropType<ExecutionStatus[]>,
    required: true,
  },
  excludeResultModal: {
    type: Boolean,
    required: false,
    default: false,
  },
  selectedScript: {
    type: Object as PropType<Script>,
    required: true,
  },
})

const emit = defineEmits<{
  (event: 'select-item', result: ExecutionStatus): void
}>()

const handleShowResult = (value: ExecutionStatus) => {
  selectedExecutionResult.value = value
  isExecuteModalVisible.value = true
}

const handleSelectResult = (result: ExecutionStatus) => {
  selectedExecutionResult.value = result
  emit('select-item', result)
}
</script>
