<template>
  <div :class="$attrs.class">
    <div v-if="props.themes == 'light'">
      <img v-if="theme.get() == 'dark'" src="/images/logos/monitic-logo.png" />
      <img v-if="theme.get() == 'light'" src="/images/logos/monitic-logo-light.png" />
    </div>

    <div v-if="props.themes == 'dark'">
      <img src="/images/logos/monitic-logo.png" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAttrs } from 'vue'
import { useColors } from 'vuestic-ui'

useAttrs()
const { currentPresetName } = useColors()

const theme = {
  get() {
    return currentPresetName.value
  },
}

const props = withDefaults(
  defineProps<{
    height?: number
    start?: string
    end?: string
    themes?: string
  }>(),
  {
    height: 18,
    start: 'primary',
    end: undefined,
    themes: 'light',
  },
)
</script>
