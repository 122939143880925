<template>
  <VaNavbar class="app-layout-navbar py-2 px-0">
    <template #left>
      <div class="left">
        <Transition v-if="isMobile" name="icon-fade" mode="out-in">
          <VaIcon color="primary" :name="isSidebarMinimized ? 'menu' : 'close'" size="24px" class="menu-icon" @click="isSidebarMinimized = !isSidebarMinimized" />
        </Transition>
        <RouterLink to="/" aria-label="Visit home page" class="logo-container">
          <VuesticLogo :themes="'light'" class="responsive-logo" />
        </RouterLink>
      </div>
    </template>
    <template #right>
      <AppNavbarActions class="app-navbar__actions" :is-mobile="isMobile" />
    </template>
  </VaNavbar>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useGlobalStore } from '../../stores/global-store'
import AppNavbarActions from './components/AppNavbarActions.vue'
import VuesticLogo from '../VuesticLogo.vue'

defineProps({
  isMobile: { type: Boolean, default: false },
})

const GlobalStore = useGlobalStore()

const { isSidebarMinimized } = storeToRefs(GlobalStore)
</script>

<style lang="scss" scoped>
.va-navbar {
  z-index: 2;

  @media screen and (max-width: 950px) {
    .left {
      width: 100%;
      justify-content: center; // Logoyu ortala
    }

    .app-navbar__actions {
      display: flex;
      justify-content: space-between;
    }
  }
}

.left {
  display: flex;
  align-items: center;
  margin-left: 1rem;

  & > * {
    margin-right: 1rem;
  }

  & > *:last-child {
    margin-right: 0;
  }
}

.logo-container {
  max-width: 200px; // Logo için maksimum genişlik
  min-width: 100px; // Logo için minimum genişlik
}

.responsive-logo {
  width: 100%;
  height: auto;
  max-height: 65px; // Maksimum yükseklik
}

.menu-icon {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
}

// Küçük ekranlarda logoyu ortala
@media screen and (max-width: 480px) {
  .va-navbar {
    .left {
      justify-content: center;
    }
  }

  .logo-container {
    max-width: 150px; // Küçük ekranlarda logo boyutunu azalt
  }

  .menu-icon {
    left: 0.5rem; // Menü ikonunu biraz sola kaydır
  }
}

.icon-fade-enter-active,
.icon-fade-leave-active {
  transition: transform 0.5s ease;
}

.icon-fade-enter,
.icon-fade-leave-to {
  transform: scale(0.5);
}
</style>
