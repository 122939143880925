<template>
  <section class="flex flex-row mb-2 justify-start items-center">
    <VaButton class="me-4" color="info" size="small" @click="handleBack">Go back</VaButton>
    <h1 class="page-title">{{ selectedUser.first_name }} {{ selectedUser.last_name }}</h1>
  </section>

  <section class="flex flex-col max-h-[calc(100vh-250px)] h-[calc(100vh-250px)]">
    <UserLicenses :selected-user="selectedUser"></UserLicenses>
  </section>
</template>

<script setup lang="ts">
import { ref, onBeforeMount } from 'vue'
import { useUsers } from '../users/composables/useUsers'
import { useRoute } from 'vue-router'
import { User } from '../users/composables/typeUsers'
import UserLicenses from './widgets/UserLicenses.vue'
import router from '../../../router'

const selectedUser = ref({} as User)
const user_id = useRoute().params.user_id.toString()
const { getUserById } = useUsers()

onBeforeMount(async () => {
  try {
    selectedUser.value = await getUserById(user_id)
  } catch (error) {
    console.log('detail err:', error)
    router.push('/users')
  }
})

const handleBack = () => {
  router.push('/users')
}
</script>
