import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'
import UserDetail from '../pages/users/user/UserDetailPage.vue'
import UsersPage from '../pages/users/users/UsersPage.vue'
import ScriptsPage from '../pages/dynamic-scripts/ScriptsPage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'monitic-settings',
        path: 'monitic-settings',
        component: () => import('../pages/monitic-settings/MoniticSettings.vue'),
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
      },
      {
        name: 'licenses',
        path: 'licenses',
        component: () => import('../pages/licenses/LicensesPage.vue'),
      },
      {
        name: 'users',
        path: 'users',
        component: UsersPage,
        children: [],
      },
      {
        name: 'dynamic-scripts',
        path: 'dynamic-scripts',
        component: ScriptsPage,
        children: [],
      },
      {
        name: 'user-detail',
        path: '/users/:user_id',
        component: UserDetail,
      },
      {
        name: 'agents',
        path: 'agents',
        component: () => import('../pages/agents/AgentsPage.vue'),
      },
      {
        name: 'licenses/addons',
        path: 'licenses/addons',
        component: () => import('../pages/license-addons/AddonsPage.vue'),
      },
      {
        name: 'licenses/vouchers',
        path: 'licenses/vouchers',
        component: () => import('../pages/license-vouchers/VouchersPage.vue'),
      },
      {
        name: 'licenses/keys',
        path: 'licenses/keys',
        component: () => import('../pages/license-keys/KeysPage.vue'),
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
      {
        path: 'logout',
        name: 'logout',
        component: () => import('../pages/auth/Logout'),
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    // For some reason using documentation example doesn't scroll on page navigation.
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

export default router
