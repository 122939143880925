// src/pages/license-addons/composables/useAddons.ts
import { reactive, ref, watch } from 'vue'
import { Addon } from './typeAddons'
import { useToast } from 'vuestic-ui'
import axiosInstance from '../../../services/interceptor'
import { SelectableOption } from 'vuestic-ui/dist/types/composables'

export function useAddons() {
  const addons = ref<Addon[]>([])
  const isLoading = ref(false)
  const { init: notify } = useToast()

  const pagination = ref({
    current_page: 1,
    total: 1,
    total_pages: 1,
  })

  const params = ref<Record<string, SelectableOption>>({
    sort_by: 'name' as string | null,
    sort: 'desc' as 'asc' | 'desc' | null,
    current_page: 1,
    limit: 10,
  })

  const fetchAddons = async (oParams: object = {}) => {
    isLoading.value = true
    try {
      const p = {
        ...params.value,
        ...oParams,
      }
      const filteredParams = Object.fromEntries(Object.entries(p).filter(([_, v]) => v !== null && v !== undefined))

      const response = await axiosInstance.get('/addons?' + new URLSearchParams(filteredParams as any))
      addons.value = !response.data.data ? ([] as Addon[]) : response.data.data
      params.value.current_page = response.data.current_page
      pagination.value.total = response.data.total
      pagination.value.total_pages = response.data.total_pages
      return response.data.data ?? ([] as Addon[])
    } catch (error: any) {
      notify({
        message: `Error: ${error.response.data.error ?? error.message}`,
        color: 'danger',
      })
    } finally {
      isLoading.value = false
    }
  }

  const createAddon = async (newAddon: Addon) => {
    try {
      const response = await axiosInstance.post<Addon>('/addons', newAddon)
      addons.value.push(response.data)
    } catch (error: any) {
      throw error
    }
  }

  const updateAddon = async (updatedAddons: Addon) => {
    try {
      await axiosInstance.patch(`/addons/${updatedAddons._id}`, updatedAddons)
      const index = addons.value.findIndex((addon) => addon._id === updatedAddons._id)
      if (index !== -1) {
        addons.value[index] = updatedAddons
      }
    } catch (error: any) {
      throw error
    }
  }

  const deleteAddon = async (addonId: string) => {
    try {
      await axiosInstance.delete(`/addons/${addonId}`)
    } catch (error: any) {
      throw error
    }
  }

  watch(
    params,
    async () => {
      await fetchAddons(params.value)
    },
    { deep: true },
  )

  return {
    addons,
    isLoading,
    pagination,
    params,
    fetchAddons,
    createAddon,
    updateAddon,
    deleteAddon,
  }
}
