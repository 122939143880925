// export default {
//   ApiUrl: 'https://192.168.20.61:3000/admin/v1',
//   LoginApi: 'https://192.168.20.61:3000/api/v1',
//   WebSocketUrl: 'wss://192.168.20.61:3000',
// }

export default {
  ApiUrl: 'https://api.monitic.com/admin/v1',
  LoginApi: 'https://api.monitic.com/api/v1',
  WebSocketUrl: 'wss://api.monitic.com',
}

// export default {
//   ApiUrl: 'https://devapi.monitic.com/admin/v1',
//   LoginApi: 'https://devapi.monitic.com/api/v1',
//   WebSocketUrl: 'wss://devapi.monitic.com',
// }
