<template>
  <VaForm v-slot="{ isValid }" ref="edit-user-license-form" class="flex-col justify-start items-start gap-4 inline-flex w-full">
    <!-- Input fields for Usage Limit and Usage Per User Limit -->
    <div class="flex gap-4 flex-col sm:flex-row w-full">
      <VaInput
        v-model.number="props.selectedUserLicense.user_license.used_agent_limit"
        label="Usage Limit"
        class="w-full sm:w-1/2"
        type="number"
        :rules="[validators.required]"
        name="usageLimit"
      />
      <VaInput
        v-model.number="props.selectedUserLicense.user_license.used_sub_account_limit"
        label="Usage Per User Limit"
        class="w-full sm:w-1/2"
        type="number"
        :rules="[validators.required]"
        name="usagePerUserLimit"
      />
    </div>

    <!-- Input fields for Start Date and Expire Date -->
    <div class="flex gap-4 flex-col sm:flex-row w-full">
      <VaInput v-model="startDateInput" label="Start Date" class="w-full sm:w-1/2" type="datetime-local" :rules="[validators.required]" name="startDate" />
      <VaInput v-model="expireDateInput" label="Expire Date" class="w-full sm:w-1/2" type="datetime-local" :rules="[validators.required]" name="expireDate" />
    </div>

    <!-- Select field for Addons with search feature -->
    <div class="flex gap-4 flex-col sm:flex-row w-full">
      <VaSelect
        v-model="addonsIdsSelect"
        track-by="value"
        label="Addons"
        class="w-full sm:w-1/2"
        :options="addonsOptions"
        multiple
        name="addons"
        searchable
        highlight-matched-text
        @search="onSearchAddons"
      />
    </div>

    <!-- Action buttons for saving or canceling -->
    <div class="flex gap-2 flex-col-reverse items-stretch justify-end w-full sm:flex-row sm:items-center">
      <VaButton preset="secondary" color="secondary" @click="$emit('close')">Cancel</VaButton>
      <VaButton :disabled="!isValid" @click="() => isValid && onSave()">Add</VaButton>
    </div>
  </VaForm>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, PropType } from 'vue'
import { VaButton, VaForm, VaInput, VaSelect } from 'vuestic-ui'
import { validators } from '../../../../services/utils'
import { useLicenses } from '../../../licenses/composables/useLicenses'
import { useAddons } from '../../../license-addons/composables/useAddons'

// Fetching licenses and addons
const { fetchLicenses, licenses } = useLicenses()
const { fetchAddons } = useAddons()

// Define emits for parent-child communication
const emit = defineEmits(['close', 'save'])

// Define reactive variables
const licensesIdsSelect = ref([])
const addonsIdsSelect = ref([])

const licenseOptions = ref([])
const addonsOptions = ref([])

const startDateInput = ref('')
const expireDateInput = ref('')

// Define props for the component
const props = defineProps({
  selectedUserLicense: {
    type: Object as PropType<any>,
    required: true,
  },
})

// Fetch initial data on component mount
onMounted(async () => {
  await loadAddons() // Initial fetch
  await loadLicenses() // Initial fetch

  startDateInput.value = new Date(props.selectedUserLicense.user_license.start_date).toISOString().slice(0, 16)
  expireDateInput.value = new Date(props.selectedUserLicense.user_license.end_date).toISOString().slice(0, 16)
})

// Function to load addons based on search query
const loadAddons = async (searchQuery = '') => {
  const result = await fetchAddons({
    limit: 9999,
    name: searchQuery,
  })
  addonsOptions.value = result.map((addon: any) => ({ text: addon.name, value: addon._id })) as any
  addonsIdsSelect.value = result
    .filter((t: any) => props.selectedUserLicense.user_license.addon_ids?.includes(t._id))
    .map((addon: any) => ({ text: addon.name, value: addon._id })) as any
}

// Function to load licenses
const loadLicenses = async () => {
  licenses.value = await fetchLicenses()
  licenseOptions.value = licenses.value.map((license) => ({ text: license.name, value: license._id })) as any
}

// Handle search event for addons
const onSearchAddons = async (searchQuery: string) => {
  await loadAddons(searchQuery)
}

// Watchers to handle changes in selected values
watch(licensesIdsSelect, (newVal: any) => {
  props.selectedUserLicense.user_license.license_id = newVal.value
})

watch(addonsIdsSelect, (newVal) => {
  props.selectedUserLicense.user_license.addon_ids = newVal.map((item: any) => (item.value ? item.value : item)) as []
})

// Update selectedUserLicense on save
const onSave = async () => {
  try {
    props.selectedUserLicense.user_license.start_date = new Date(startDateInput.value).toISOString()
    props.selectedUserLicense.user_license.end_date = new Date(expireDateInput.value).toISOString()
    emit('save', props.selectedUserLicense)
  } catch (error) {
    console.error(error)
  }
}
</script>

<style scoped>
/* Custom styles can be defined here */
</style>
