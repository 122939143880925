<template>
  <div v-if="selectedExecutionResult2?.agent" class="flex flex-col">
    <div class="flex flex-row">
      <h2 class="flex gap-2 text-lg font-semibold mb-2 cursor-default">
        {{ selectedScript?.name }}
        <p class="font-normal">
          |
          {{ selectedExecutionResult2?.agent.basic_data.hostname }}
          {{ selectedExecutionResult2?.agent.name ? ' - ' + selectedExecutionResult2?.agent.name : '' }}
        </p>
      </h2>
    </div>

    <div class="flex flex-row">
      <div class="flex w-2/6 pe-3 overflow-auto">
        <ResultsListItem :results="results" :selected-script="selectedScript" :exclude-result-modal="false" @selectItem="handleSelect" />
      </div>
      <div class="flex flex-col h-full w-full rounded-lg shadow-sm border border-gray-200 p-2">
        <div class="bg-[#1e1e1e] p-2">
          <MonacoEditor ref="resultHandle" :model-value="selectedExecutionResult2?.response.task.raw_response" :options="editorOptions" />
        </div>
      </div>
    </div>
  </div>

  <div class="flex flex-col-reverse items-stretch justify-end w-full gap-2 sm:flex-row sm:items-center mt-4">
    <VaButton color="primary" @click="$emit('close')">OK</VaButton>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, PropType, ref } from 'vue'
import MonacoEditor from '../../../components/monaco/MonacoEditor.vue'
import { ExecutionStatus } from '../composables/typeExecutionStatus'
import ResultsListItem from '../widgets/ResultsListItem.vue'
import { Script } from '../composables/typeScripts'

const selectedExecutionResult2 = ref<ExecutionStatus>()

const props = defineProps({
  results: {
    type: Array as PropType<ExecutionStatus[]>,
    required: true,
  },
  selectedExecutionResult: {
    type: Object as PropType<ExecutionStatus>,
    required: false,
    default: {},
  },
  selectedScript: {
    type: Object as PropType<Script>,
    required: true,
  },
})

const editorOptions = {
  fontSize: 12,
  minimap: { enabled: true },
  lineNumbers: 'on',
  automaticLayout: true,
}

const handleSelect = (result: ExecutionStatus) => {
  selectedExecutionResult2.value = result
}

onMounted(async () => {
  selectedExecutionResult2.value = props.results[0]
  if (props.selectedExecutionResult.agent) {
    selectedExecutionResult2.value = props.selectedExecutionResult
  }
})
</script>
