<template>
  <div class="flex-1 w-full bg-white rounded-lg shadow-sm border border-gray-200 p-3 flex flex-col mt-4">
    <VaForm v-slot="{ isValid }" ref="execution_modal_params_form">
      <div class="flex flex-row justify-start items-center mb-3 gap-2 cursor-default">
        <h2 class="font-semibold">Script:</h2>
        {{ selectedScript.name }}
      </div>

      <div class="flex flex-row gap-2" style="">
        <div class="flex-1 w-full sm:w-1/3 bg-white rounded-lg shadow-sm border border-gray-200 p-3 flex flex-col justify-between overflow-auto">
          <div>
            <div class="flex flex-row justify-between items-start">
              <h2 class="text-lg font-semibold mb-3 cursor-default">Select Agent</h2>
            </div>
            <div class="flex flex-row gap-2 mb-2">
              <VaInput ref="searchInput" class="w-1/2" placeholder="Filter..." @keyup.enter="handleSearch" />
              <VaSelect
                v-model="agentsFilters.user_id"
                class="w-1/2"
                placeholder="Users..."
                :options="users"
                searchable
                :text-by="(user: User) => user.first_name + ' ' + user.last_name"
                :value-by="(user: User) => user.id"
              />
            </div>

            <div style="overflow: auto">
              <VaDataTable
                v-model="selectedAgents"
                :loading="agentsIsLoading"
                :items="agents"
                :columns="columns"
                select-mode="multiple"
                selectable
                sticky-header
                clickable
                items-track-by="hostname"
                :row-bind="getRowBind"
              >
                <template #cell(image)="{ rowData }">
                  <div class="flex gap-2 content-end pe-0 ps-0">
                    <img class="max-h-9" :src="getOsImageSrc(rowData.os)" />
                  </div>
                </template>

                <template #cell(hostname)="{ rowData }">
                  <div class="flex flex-col items-start max-w-[230px] ellipsis">
                    {{ rowData.basic_data.hostname }}
                    <p class="text text-gray-600 text-xs text-wrap font-thin">{{ rowData.name }}</p>
                  </div>
                </template>
              </VaDataTable>
            </div>
            <div v-if="agentsPagination.totalPages > 1" class="w-full flex justify-end">
              <VaPagination
                v-model="agentsPagination.currentPage"
                :pages="agentsPagination.totalPages"
                :visible-pages="5"
                :boundary-links="false"
                :direction-links="true"
                @update:modelValue="updateCurrentPage"
              />
              <VaSelect v-model="agentsPagination.itemsPerPage" :options="[10, 20, 50, 100]" @update:modelValue="updateItemsPerPage" />
            </div>
          </div>
          <div class="flex justify-end mt-2">
            <VaButton
              v-if="selectedAgents.length > 0"
              :loading="executionStatus"
              :disabled="executionStatus || !isValid"
              size="small"
              class="w-full mt-2"
              @click="executeRequests"
            >
              Execute
            </VaButton>
          </div>
        </div>

        <div v-if="showResultsPanel" class="flex-1 w-full sm:w-2/3 bg-white rounded-lg shadow-sm border border-gray-200 p-3 flex flex-col">
          <h2 class="text-lg font-semibold mb-3 cursor-default">Execution Status</h2>

          <div class="flex flex-row gap-2 mb-2">
            <VaInput class="w-1/2" placeholder="Filter..." />
            <VaSelect class="w-1/2" placeholder="Company..." :options="['All Companys']" />
          </div>

          <div class="flex-grow bg-gray-50 rounded-lg shadow-inner p-2">
            <div class="flex flex-row justify-between">
              <h3 class="text-lg font-semibold text-gray-700">Results</h3>
              <div class="flex flex-row justify-end gap-2">
                <VaButton size="small" color="info" class="mb-1" @click="handleClear">Clear</VaButton>
                <VaButton :disabled="results.length < 1" size="small" color="warning" class="mb-1" @click="handleShowResult(results[0])">Detailed View</VaButton>
              </div>
            </div>
            <hr />

            <div style="max-height: 38vh; overflow: auto" class="mt-1">
              <ResultsListItem :results="results" :selected-script="selectedScript" :exclude-result-modal="true" />
            </div>
          </div>
          <div class="mt-4">
            <VaProgressBar :model-value="progress" show-percent />
          </div>
          <div class="flex justify-end mt-2">
            <VaButton :disabled="!executionStatus" size="small" class="w-full" @click="stopExecution">Stop</VaButton>
          </div>
        </div>
      </div>

      <div class="p-3 flex flex-col overflow-auto">
        <div class="flex flex-row">
          <div>
            <h2 class="text-lg font-semibold mb-3 cursor-default">Params</h2>

            <div class="flex flex-row">
              <VaInput
                v-model="executionTimeout"
                class="flex pe-2 w-28 text-xs"
                label="Timeout (seconds)"
                required-mark
                type="number"
                step="1"
                :rules="[validators.required, validators.atMax3600Val]"
              />

              <div v-for="(item, keyParam) in selectedScript.parameters" :key="keyParam" class="flex flex-col">
                <VaInput
                  v-if="selectedScript.parameters.length > 0"
                  v-model="scriptParams[item.name]"
                  class="flex pe-2 w-28"
                  :label="item.name"
                  :required-mark="item.required"
                  :rules="item.required ? [validators.required] : []"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </VaForm>
  </div>

  <VaModal v-model="isExecuteModalVisible" size="large" hide-default-actions>
    <ResultsShowModal :results="results" :selected-script="selectedScript" @close="isExecuteModalVisible = false" />
  </VaModal>
</template>

<script lang="ts" setup>
import { onMounted, PropType, ref, watch } from 'vue'
import { useAgents } from '../../agents/composables/useAgents'
import { useScripts } from './../composables/useScripts'
import { Agent } from '../../agents/composables/typeAgents'
import { Script } from '../composables/typeScripts'
import { useUsers } from '../../users/users/composables/useUsers'
import { User } from '../../users/users/composables/typeUsers'
import { validators } from '../../../services/utils'
import { DataTableColumnSource, VaButton, VaForm, VaInput, VaSelect } from 'vuestic-ui'
import { ExecutionStatus } from '../composables/typeExecutionStatus'
import ResultsShowModal from './ResultsShowModal.vue'
import ResultsListItem from '../widgets/ResultsListItem.vue'
import { debounce } from 'lodash'

const { agents, isLoading: agentsIsLoading, pagination: agentsPagination, filters: agentsFilters, fetchAgents } = useAgents()
const { executeScriptBySelectedAgent } = useScripts()
const { params: userParams, users } = useUsers()
const selectedAgents = ref<Agent[]>([])
const executionStatus = ref<boolean>(false)
const showResultsPanel = ref<boolean>(false)
const progress = ref<number>(0)
const results = ref<ExecutionStatus[]>([])
const searchInput = ref()
const scriptParams = ref<Record<string, string>>({})
const execution_modal_params_form = ref<any>()
const executionTimeout = ref<any>(15)
const selectedExecutionResult = ref<ExecutionStatus>()
const isExecuteModalVisible = ref(false)

const props = defineProps({
  selectedScript: {
    type: Object as PropType<Script>,
    required: true,
  },
})

const columns: DataTableColumnSource<string>[] = [
  {
    key: 'image',
    label: '#',
    thAlign: 'left',
    tdAlign: 'left',
    tdVerticalAlign: 'middle',
    width: '45px',
  },
  {
    key: 'hostname',
    label: 'Agent',
    thAlign: 'left',
    tdAlign: 'center',
    tdVerticalAlign: 'top',
  },
]

const updateCurrentPage = (newPage: number) => {
  agentsPagination.currentPage = newPage
  fetchAgents()
}

const updateItemsPerPage = (newItemsPerPage: number) => {
  agentsPagination.itemsPerPage = newItemsPerPage
  agentsPagination.currentPage = 1
  fetchAgents()
}

const debouncedSearch = debounce(() => {
  agentsFilters['basic_data.hostname'] = searchInput.value?.$el.querySelector('input').value
  agentsPagination.currentPage = 1
  fetchAgents()
}, 300)

watch(
  () => agentsFilters.user_id,
  () => {
    agentsPagination.currentPage = 1
    fetchAgents()
  },
)

const handleSearch = () => {
  debouncedSearch()
}

const executeRequests = async () => {
  executionStatus.value = true
  showResultsPanel.value = true
  progress.value = 0
  results.value = []

  const totalAgents = selectedAgents.value.length
  let completedRequests = 0

  const requests = selectedAgents.value.map(async (agent) => {
    try {
      const response = await executeScriptBySelectedAgent(props.selectedScript, agent, scriptParams.value, executionTimeout.value)

      results.value.push({
        response: response,
        status: true,
        agent: agent,
      })
    } catch (error: any) {
      console.error('Hata:', error)
      results.value.push({
        response: error.response || { task: null },
        status: false,
        agent: agent,
      })
    }

    completedRequests += 1
    progress.value = Math.ceil((completedRequests / totalAgents) * 100)
  })

  try {
    await Promise.all(requests)
  } catch (error) {
    console.error('Toplu hata:', error)
  }

  executionStatus.value = false
}

const stopExecution = () => {
  executionStatus.value = false
  progress.value = 0
}

const handleClear = () => {
  results.value = []
  progress.value = 0
  executionStatus.value = false
}

const getRowBind = (row: Agent) => {
  if (!row.is_online) {
    return {
      class: 'bg-red-50 text-slate-500',
      style: 'pointer-events: none',
    }
  }
}

onMounted(async () => {
  props.selectedScript.parameters.forEach((param) => {
    scriptParams.value[param.name] = param.default || ''
  })

  agentsFilters.is_online = true
  agentsFilters.os = props.selectedScript.os

  userParams.value.limit = 9999

  execution_modal_params_form.value?.resetValidation()

  fetchAgents()
})

const getOsImageSrc = (osName: string) => {
  if (osName === 'windows') {
    return '/images/colored/windows-colored.png'
  } else if (osName === 'linux') {
    return '/images/colored/linux-colored.png'
  } else if (osName === 'macos') {
    return '/images/colored/macos-colored.png'
  } else {
    return '/images/colored/default.png'
  }
}

const handleShowResult = (value: ExecutionStatus) => {
  selectedExecutionResult.value = value
  isExecuteModalVisible.value = true
}
</script>
<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: width 1s;
}

.v-enter-from,
.v-leave-to {
  width: 0;
}
</style>
