import router from '../../router'
import config from '../../services/config'
import axiosInstance from '../../services/interceptor'
import { useUserStore } from '../../stores/user-store'

export const logout = async () => {
  const userStore = useUserStore()

  try {
    await axiosInstance.get(config.LoginApi + '/logout')
    userStore.clearUser()

    axiosInstance.defaults.headers.common['Authorization'] = ''

    // Kullanıcıyı giriş sayfasına yönlendir
    router.push({ name: 'login', query: { logout: 'success' } })
  } catch (err) {
    router.push({ name: 'login', query: { logout: 'error' } })
  }
}
