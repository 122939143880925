import { ref, watch } from 'vue'
import { License } from './typeLicenses'
import { CreateRequest } from './requestLicenses'
import { useToast } from 'vuestic-ui/web-components'
import axiosInstance from '../../../services/interceptor'
import { SelectableOption } from 'vuestic-ui/dist/types/composables'

export const useLicenses = () => {
  const licenses = ref<License[]>([])
  const isLoading = ref(false)

  const { init: notify } = useToast()
  const pagination = ref({
    current_page: 1,
    total: 1,
    total_pages: 1,
  })

  const params = ref<Record<string, SelectableOption>>({
    sort_by: 'status' as string | null,
    sort: 'desc' as 'asc' | 'desc' | null,
    current_page: 1,
    limit: 10,
  })

  const fetchLicenses = async (oParams: object = {}) => {
    isLoading.value = true
    try {
      const p = {
        ...params.value,
        ...oParams,
      }
      const filteredParams = Object.fromEntries(Object.entries(p).filter(([_, v]) => v !== null && v !== undefined))
      const response = await axiosInstance.get('/licenses?' + new URLSearchParams(filteredParams as any))
      licenses.value = !response.data.data ? ([] as License[]) : response.data.data
      params.value.current_page = response.data.current_page
      pagination.value.total = response.data.total
      pagination.value.total_pages = response.data.total_pages
      return response.data.data ?? ([] as License[])
    } catch (error: any) {
      notify({
        message: `Error: ${error.message}`,
        color: 'danger',
      })
    } finally {
      isLoading.value = false
    }
  }

  const deleteLicense = async (id: string) => {
    try {
      await axiosInstance.delete(`/licenses/${id}`)
      await fetchLicenses()
    } catch (error: any) {
      notify({
        message: `Error: ${error.message}`,
        color: 'danger',
      })
    }
  }

  const updateLicense = async (license: License) => {
    try {
      const response = await axiosInstance.patch(`/licenses/${license._id}`, license)
      await fetchLicenses()
    } catch (error: any) {
      notify({
        message: `Error: ${error.message}`,
        color: 'danger',
      })
    }
  }

  const createLicense = async (license: CreateRequest) => {
    try {
      const response = await axiosInstance.post('/licenses', license)
      await fetchLicenses()
    } catch (error: any) {
      notify({
        message: `Error: ${error.message}`,
        color: 'danger',
      })
    }
  }

  const getLicenseById = async (license_id: string) => {
    try {
      var response = await axiosInstance.get(`/licenses/${license_id}`)
      return response.data.data
    } catch (error) {
      throw error
    }
  }

  watch(
    params,
    async () => {
      await fetchLicenses(params.value)
    },
    { deep: true },
  )

  return {
    licenses,
    isLoading,
    pagination,
    params,
    fetchLicenses,
    deleteLicense,
    updateLicense,
    createLicense,
    getLicenseById,
  }
}
