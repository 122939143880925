import { ref, reactive, watch } from 'vue'
import { Agent } from './typeAgents'
import { useToast } from 'vuestic-ui/web-components'
import axiosInstance from '../../../services/interceptor'

export const useAgents = () => {
  const agents = ref<Agent[]>([])
  const isLoading = ref(false)
  const { init: notify } = useToast()

  const pagination = reactive({
    currentPage: 1,
    totalPages: 1,
    totalItems: 0,
    itemsPerPage: 10,
  })

  const filters = reactive<Record<string, any>>({})

  const setFilter = (key: string, value: any) => {
    if (value === null || value === '') {
      delete filters[key]
    } else {
      filters[key] = value
    }
  }

  const clearFilters = () => {
    Object.keys(filters).forEach((key) => delete filters[key])
  }

  const fetchAgents = async () => {
    isLoading.value = true
    try {
      const response = await axiosInstance.post('/agents', filters, {
        params: {
          page: pagination.currentPage,
          limit: pagination.itemsPerPage,
        },
      })
      agents.value = response.data.data || []
      pagination.totalItems = response.data.totalCount
      pagination.totalPages = response.data.totalPages
    } catch (error: any) {
      notify({
        message: `Error: ${error.message}`,
        color: 'danger',
      })
    } finally {
      isLoading.value = false
    }
  }

  watch([() => pagination.currentPage, () => pagination.itemsPerPage, filters], fetchAgents, { deep: true })

  return {
    agents,
    isLoading,
    pagination,
    filters,
    setFilter,
    clearFilters,
    fetchAgents,
  }
}
