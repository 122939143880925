<template>
  <VaDataTable v-model:sort-by="params.sort_by" v-model:sorting-order="params.sort" :items="scripts" :columns="columns" :loading="loading">
    <template #cell(os)="{ rowData }">
      <div class="flex gap-2 justify-center pe-0 ps-0">
        <img class="max-h-9" :src="getOsImageSrc(rowData.os)" :style="[rowData.enabled ? {} : { opacity: 0.5 }]" />
      </div>
    </template>

    <template #cell(name)="{ rowData }">
      <div class="flex flex-col items-start gap-2 ellipsis">
        {{ rowData.name }}
        <p class="text text-gray-600 text-xs text-wrap">{{ rowData.description }}</p>
      </div>
    </template>
    <template #cell(created_at)="{ rowData }">
      <div class="flex flex-col items-start gap-2 ellipsis">
        <div>
          <p class="text text-gray-600 text-xs">
            Created:
            {{ new Date(rowData.created_at as any).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }) }}
          </p>
        </div>
        <div>
          <p class="text text-gray-600 text-xs">
            Updated:
            {{ new Date(rowData.updated_at as any).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }) }}
          </p>
        </div>
      </div>
    </template>

    <template v-if="scripts && scripts.length > 0" #cell(actions)="{ row, rowData, isExpanded }">
      <div class="flex gap-2 justify-end">
        <VaButton preset="primary" size="small" icon="mso-order_play" aria-label="Execute script" @click="emitExecuteScript(rowData as Script)" />
        <VaButton preset="primary" size="small" icon="mso-edit" aria-label="Edit script" @click="emitEditScript(rowData as Script)" />
        <VaButton preset="primary" size="small" icon="mso-delete" color="danger" aria-label="Delete script" @click="emitDeleteScript(rowData as Script)" />
        <VaButton preset="primary" size="small" icon="mso-download" aria-label="Export script" @click="showExportModal(rowData as Script)" />
        <VaButton :icon="isExpanded ? 'va-arrow-up' : 'va-arrow-down'" preset="primary" size="small" @click="row.toggleRowDetails()" />
      </div>
    </template>

    <template #expandableRow="{ rowData, toggleRowDetails }">
      <TableExpandableRow :row-data="rowData" @collapse="() => toggleRowDetails()" />
    </template>
  </VaDataTable>

  <VaModal v-model="isExportModalVisible" :title="exportModalTitle" ok-text="Download" cancel-text="Close" @ok="downloadExport">
    <div class="relative">
      <VaButton class="absolute top-2 right-2 z-10" icon="mso-content_copy" preset="secondary" size="small" @click="copyValue">Copy </VaButton>
      <pre class="overflow-auto max-h-96 p-4 bg-gray-100 rounded">{{ JSON.stringify(exportContent, null, 2) }}</pre>
    </div>
  </VaModal>

  <div class="flex flex-col-reverse md:flex-row gap-2 justify-between items-center py-2">
    <div>
      <b>{{ pagination.total }} results.</b>
      Results per page
      <VaSelect v-model="params.limit" class="!w-20" :options="[10, 50, 100]" />
    </div>

    <div class="flex gap-2">
      <!-- <VaButton preset="secondary" icon="mso-download" aria-label="Previous page" size="small" @click="showExportModal(null)">Export Scripts</VaButton> -->

      <div v-if="pagination.total_pages" class="flex">
        <VaButton preset="secondary" icon="va-arrow-left" aria-label="Previous page" @click="prevPage" />
        <VaButton class="mr-2" preset="secondary" icon="va-arrow-right" aria-label="Next page" @click="nextPage" />
        <VaPagination
          v-model="params.page"
          buttons-preset="secondary"
          :pages="pagination.total_pages"
          :visible-pages="5"
          :boundary-links="false"
          :direction-links="false"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { PropType } from 'vue'
import { DataTableColumnSource, useModal, VaDataTable } from 'vuestic-ui'
import { Script } from '../composables/typeScripts'

import TableExpandableRow from './TableExpandableRow.vue'
import { Pagination } from '../../../data/typePagination'
import { copyToClipboard } from '../../../services/utils'

const { confirm } = useModal()

const props = defineProps({
  scripts: {
    type: Array as PropType<Script[]>,
    default: [],
  },
  loading: {
    type: Boolean,
    default: false,
  },
  params: {
    type: Object as any,
    default: {},
  },
  pagination: {
    type: Object as PropType<Pagination>,
    required: true,
  },
})

const emit = defineEmits<{
  (event: 'editScript', script: Script): void
  (event: 'delete', script: Script): void
  (event: 'execute', script: Script): void
}>()

const columns: DataTableColumnSource<string>[] = [
  { label: 'OS', key: 'os', sortable: true, thAlign: 'left', tdAlign: 'center', tdVerticalAlign: 'middle', width: '30px' },
  { label: 'Name', key: 'name', sortable: true, align: 'left', width: '50%' },
  { label: 'Date', key: 'created_at', sortable: true, tdAlign: 'left', width: '20%' },
  { label: 'Actions', key: 'actions', sortable: false, thAlign: 'right', tdAlign: 'right', tdVerticalAlign: 'middle', width: '200px' },
]

const emitExecuteScript = (script: Script) => {
  emit('execute', script)
}

const emitEditScript = (script: Script) => {
  emit('editScript', script)
}

const emitDeleteScript = async (script: Script) => {
  const agreed = await confirm({
    title: 'Delete script',
    message: `Are you sure you want to delete ${script.name}?`,
    okText: 'Delete',
    cancelText: 'Cancel',
    size: 'small',
    maxWidth: '380px',
  })
  if (agreed) {
    emit('delete', script)
  }
}

const getOsImageSrc = (osName: string) => {
  if (osName === 'windows') {
    return '/images/colored/windows-colored.png'
  } else if (osName === 'linux') {
    return '/images/colored/linux-colored.png'
  } else if (osName === 'macos') {
    return '/images/colored/macos-colored.png'
  } else {
    return '/images/colored/default.png'
  }
}

const prevPage = () => {
  if (props.params.page > 1) {
    props.params.page--
  }
}

const nextPage = () => {
  if (props.params.page < props.pagination.total_pages) {
    props.params.page++
  }
}

const isExportModalVisible = ref(false)
const exportModalTitle = ref('')
const exportContent = ref<any>(null)
const currentExportFileName = ref('')

const showExportModal = (script: Script | null) => {
  if (script) {
    exportModalTitle.value = `Export Script: ${script.name}`
    exportContent.value = convertScriptToOpenAPI(script)
    currentExportFileName.value = `apidog_script_${script.name}`
  } else {
    exportModalTitle.value = 'Export All Scripts'
    exportContent.value = convertScriptsToOpenAPI(props.scripts)
    currentExportFileName.value = 'apidog_all_scripts'
  }
  isExportModalVisible.value = true
}

const downloadExport = () => {
  downloadObjectAsJson(exportContent.value, currentExportFileName.value)
  isExportModalVisible.value = false
}

const copyValue = () => {
  copyToClipboard(JSON.stringify(exportContent.value, null, 2))
}

const convertScriptToOpenAPI = (script: Script) => {
  const paths: any = {}
  paths[`/execute/${script.name}`] = {
    post: {
      summary: script.description,
      parameters: script.parameters.map((param) => ({
        name: param.name,
        in: 'query',
        description: param.description,
        required: param.required,
        schema: {
          type: param.type,
        },
      })),
      responses: {
        '200': {
          description: 'Successful response',
          content: {
            'application/json': {
              schema: {
                type: 'object',
                properties: {
                  output: {
                    type: 'string',
                  },
                },
              },
            },
          },
        },
      },
    },
  }

  return {
    openapi: '3.0.0',
    info: {
      title: `Dynamic Script API - ${script.name}`,
      version: '1.0.0',
    },
    paths,
  }
}

const convertScriptsToOpenAPI = (scripts: Script[]) => {
  const paths: any = {}

  scripts.forEach((script) => {
    paths[`/execute/${script.name}`] = {
      post: {
        summary: script.description,
        parameters: script.parameters.map((param) => ({
          name: param.name,
          in: 'query',
          description: param.description,
          required: param.required,
          schema: {
            type: param.type,
          },
        })),
        responses: {
          '200': {
            description: 'Successful response',
            content: {
              'application/json': {
                schema: {
                  type: 'object',
                  properties: {
                    output: {
                      type: 'string',
                    },
                  },
                },
              },
            },
          },
        },
      },
    }
  })

  return {
    openapi: '3.0.0',
    info: {
      title: 'Dynamic Scripts API',
      version: '1.0.0',
    },
    paths,
  }
}

const downloadObjectAsJson = (exportObj: any, exportName: string) => {
  const dataStr = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(exportObj))
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', exportName + '.json')
  document.body.appendChild(downloadAnchorNode)
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}
</script>

<style scoped>
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
