<template>
  <div v-if="rowData" class="p-4 bg-gray-50 border-t gap-4 text-sm">
    <div class="flex flex-wrap row mb-2">
      <VaCard class="flex-1 min-w-[80px] max-w-[90px] mr-4 mb-4" flat>
        <VaCardTitle> </VaCardTitle>
        <VaCardContent>
          <VaImage fit="contain" class="max-h-32 col-span-1" :src="getOsImageSrc(rowData.os)" />
        </VaCardContent>
      </VaCard>

      <VaCard class="flex-1 min-w-[300px] mr-4 mb-4" flat>
        <VaCardTitle>
          <p class="text-base">Script Details</p>
        </VaCardTitle>
        <VaCardContent>
          <p class="text-xs font-thin"><span class="font-semibold">ID: </span>{{ rowData._id }}</p>
          <p class="text-xs font-thin"><span class="font-semibold">OS:</span> {{ rowData.os }}</p>
          <p class="text-xs font-thin"><span class="font-semibold">Name:</span> {{ rowData.name }}</p>
          <p class="text-xs font-thin text-wrap"><span class="font-semibold">Description:</span> {{ rowData.description }}</p>
        </VaCardContent>
      </VaCard>

      <VaCard class="flex-1 min-w-[300px] mr-4 mb-4" flat>
        <VaCardTitle>
          <p class="text-base">Script</p>
        </VaCardTitle>
        <VaCardContent>
          <p class="text-xs font-thin text-wrap"><span class="font-semibold">Script: </span>{{ rowData.script }}</p>
          <p v-if="rowData.parameters.length > 0" class="text-xs font-thin text-wrap"><span class="font-extrabold">Parameters: </span></p>
          <content v-for="(param, key) in rowData.parameters" :key="key" class="flex text-wrap font-thin text-xs">
            <span class="font-semibold"
              >{{ param.name }}<span class="text-red-600">{{ param.is_required ?? '*' }}</span></span
            >
            - {{ param.description }} ({{ param.type }})
          </content>
        </VaCardContent>
      </VaCard>

      <VaCard class="flex-1 min-w-[300px] mr-4 mb-4" flat>
        <VaCardTitle>
          <p class="text-base">Others</p>
        </VaCardTitle>
        <VaCardContent>
          <p class="text-xs font-thin"><span class="font-semibold">Enabled: </span>{{ rowData.enabled }}</p>
          <p class="text-xs font-thin"><span class="font-semibold">created_by: </span>{{ rowData.created_by }}</p>
          <p class="text-xs font-thin"><span class="font-semibold">created_at: </span>{{ rowData.created_at }}</p>
          <p class="text-xs font-thin"><span class="font-semibold">updated_at: </span>{{ rowData.updated_at }}</p>
          <p class="text-xs font-thin"><span class="font-semibold">updated_by: </span>{{ rowData.updated_by }}</p>
        </VaCardContent>
      </VaCard>
    </div>
    <div class="flex justify-end">
      <VaButton class="mt-2" size="small" icon-right="va-arrow-up" @click="$emit('collapse')">Collapse</VaButton>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  rowData: {
    type: Object as any,
    required: true,
  },
})

defineEmits(['collapse'])

const getOsImageSrc = (osName: string) => {
  if (osName === 'windows') {
    return '/images/colored/windows-colored.png'
  } else if (osName === 'linux') {
    return '/images/colored/linux-colored.png'
  } else if (osName === 'macos') {
    return '/images/colored/macos-colored.png'
  } else {
    return '/images/colored/default.png'
  }
}
</script>
