<template>
  <div class="flex flex-col md:flex-row gap-2 mb-2 justify-between">
    <div class="flex flex-col md:flex-row gap-2 justify-start">
      <h1 class="card-title text-secondary font-bold text-xs uppercase">User Agents</h1>
    </div>
    <VaButtonToggle
      v-if="userLicenses.userLicense != undefined"
      v-model="selectedAgentType"
      class="ms-4"
      color="background-element"
      border-color="background-element"
      size="small"
      :options="[
        { label: 'Assigned', value: 'agents' },
        { label: 'Unassigned', value: 'unassigned-agents' },
      ]"
    />
  </div>

  <VaInput v-model.lazy.trim="searchKey" class="mb-2" placeholder="Search">
    <template #prependInner>
      <VaIcon name="search" color="secondary" />
    </template>
  </VaInput>

  <section class="w-full h-full overflow-auto">
    <div v-if="userLicenses.userLicense != undefined && mappedAgents.length > 0">
      <template v-for="(agent, agentKey) in mappedAgents" :key="agentKey">
        <div class="flex items-center justify-between md:justify-items-stretch w-full">
          <div class="flex grow flex-col space-y-2 md:flex-row md:space-y-0 md:space-x-1 justify-between items-start md:items-center w-full">
            <div class="flex items-center md:w-8 w-8">
              <img fit="contain" class="max-h-32 col-span-1" :src="getOsImageSrc(agent.basic_data.os_type)" />
            </div>
            <div class="w-full ps-2">
              <p>{{ agent.name ?? ' - ' }}</p>
              <p class="text-xs" color="secondary" style="font-size: 0.7em; color: slategray">{{ agent.basic_data.hostname }}</p>
            </div>
          </div>
          <div class="flex justify-end pe-2">
            <div v-if="selectedAgentType == 'unassigned-agents'" class="font-bold">
              <VaButton size="small" @click="assignHandle(agent)">Assign</VaButton>
            </div>

            <div v-if="selectedAgentType == 'agents'" class="font-bold">
              <VaButton size="small" color="primary" @click="unassignHandle(agent)">Unassign</VaButton>
            </div>
          </div>
        </div>
        <hr />
      </template>
    </div>
    <div v-else-if="userLicenses.userLicense == undefined">
      <span class="flex flex-col items-center justify-center text-center">
        <SelectDataSvg></SelectDataSvg>
      </span>
      <div class="flex flex-col items-center justify-center text-center">
        <span class="text-blue-500 font-bold text-lg">Select License</span>
        <p class="">To proceed, you need to select a license from the list.</p>
      </div>
    </div>

    <div v-else>
      <span class="flex flex-col items-center justify-center text-center">
        <NoDataSvg></NoDataSvg>
      </span>
      <div class="flex flex-col items-center justify-center text-center">
        <span class="text-blue-500 font-bold text-lg">No agents</span>
        <p class="">{{ userLicenses.userLicense.is_deleted ? 'Deleted user licenses cannot be assigned.' : '' }}</p>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { ref, PropType, watch } from 'vue'
import { Agent } from '../../../agents/composables/typeAgents'
import { useUserLicenses } from '../composables/useUserLicenses'
import { useModal, useToast } from 'vuestic-ui'
import SelectDataSvg from '../../../../components/svgs/select-data-svg.vue'
import NoDataSvg from '../../../../components/svgs/no-data-svg.vue'
import { UserLicenseModel } from '../composables/typeUserLicense'
import { debounce } from 'lodash'

const { confirm, init } = useModal()
const { init: notify } = useToast()
const { fetchAssignedOrUnassignedAgents, removeAgentFromUserLicense, addAgentFromUserLicense } = useUserLicenses()
const props = defineProps({
  userLicenses: {
    type: Object as PropType<UserLicenseModel>,
    required: true,
  },
})

const mappedAgents = ref<Agent[]>([])
const selectedAgentType = ref<string>('agents')
const searchKey = ref()

const debouncedSearch = debounce(async (value: string) => {
  console.log('Debounced search:', value)
  await fetchData()
}, 300)

watch(searchKey, (newVal) => {
  debouncedSearch(newVal)
})

const assignHandle = async (agent: Agent) => {
  if (props.userLicenses?.userLicense.is_deleted) {
    init({
      title: 'This license is unusable',
      message: `${props.userLicenses.parentLicense.name} has been deleted. The selected device cannot be assigned to this license.`,
      okText: 'OK',
      cancelText: '',
      size: 'small',
      maxWidth: '380px',
    })

    return
  }

  const agreed = await confirm({
    title: 'Assign agent',
    message: `Are you sure you want to assign the '${agent.basic_data.hostname}' device to '${props.userLicenses?.parentLicense.name}'?`,
    okText: 'Assign',
    cancelText: 'Cancel',
    size: 'small',
    maxWidth: '380px',
  })
  if (agreed) {
    try {
      await addAgentFromUserLicense(props.userLicenses.userLicense.user_id, props.userLicenses.userLicense._id, agent._id)
      notify({
        message: `'${agent.basic_data.hostname}' has been assigned.`,
        color: 'success',
      })
    } catch (error: any) {
      notify({
        message: error.response.data.error ?? `Error: ${error.message}`,
        color: 'danger',
      })
    } finally {
      mappedAgents.value =
        ((await fetchAssignedOrUnassignedAgents(props.userLicenses.userLicense.user_id, props.userLicenses.userLicense._id, selectedAgentType.value)) as any) ??
        ([] as any)
    }
  }
}

const unassignHandle = async (agent: Agent) => {
  const agreed = await confirm({
    title: 'Unassign agent',
    message: `Are you sure you want to unassign the '${agent.basic_data.hostname}' device to '${props.userLicenses.parentLicense.name}'?`,
    okText: 'Unassign',
    cancelText: 'Cancel',
    size: 'small',
    maxWidth: '380px',
  })
  if (agreed) {
    try {
      await removeAgentFromUserLicense(props.userLicenses.userLicense.user_id, props.userLicenses.userLicense._id, agent._id)
      notify({
        message: `'${agent.basic_data.hostname}' has been unassigned.`,
        color: 'success',
      })
    } catch (error: any) {
      notify({
        message: `Error: ${error.message}`,
        color: 'danger',
      })
    } finally {
      mappedAgents.value =
        ((await fetchAssignedOrUnassignedAgents(props.userLicenses.userLicense.user_id, props.userLicenses.userLicense._id, selectedAgentType.value)) as any) ??
        ([] as any)
    }
  }
}

const fetchData = async () => {
  if (props.userLicenses.userLicense && !props.userLicenses.userLicense.is_deleted) {
    mappedAgents.value =
      ((await fetchAssignedOrUnassignedAgents(
        props.userLicenses.userLicense.user_id,
        props.userLicenses.userLicense._id,
        selectedAgentType.value,
        searchKey.value,
      )) as any) ?? ([] as any)
  } else {
    mappedAgents.value = [] as any
  }
}
watch(
  () => props.userLicenses.userLicense,
  async (newVal, oldVal) => {
    if (newVal && (!oldVal || oldVal._id !== newVal._id) && !newVal.is_deleted) {
      await fetchData()
    } else {
      mappedAgents.value = [] as any
    }
  },
  { immediate: true, deep: true },
)

watch(selectedAgentType, fetchData)

const getOsImageSrc = (osName: string) => {
  if (osName === 'windows') {
    return '/images/colored/windows-colored.png'
  } else if (osName === 'linux') {
    return '/images/colored/linux-colored.png'
  } else if (osName === 'macos') {
    return '/images/colored/macos-colored.png'
  } else {
    return '/images/colored/default.png'
  }
}
</script>
