<template>
  <div>
    <h1 class="page-title">Dynamic Scripts</h1>

    <VaCard>
      <VaCardContent>
        <div class="flex flex-col md:flex-row gap-2 mb-2 justify-between">
          <div class="flex flex-col md:flex-row gap-2 justify-start">
            <VaInput v-model="searchInput" placeholder="Search">
              <template #prependInner>
                <VaIcon name="search" color="secondary" size="small" />
              </template>
            </VaInput>
          </div>
          <div class="flex gap-2">
            <ScriptImportExportWidget />
            <VaButton size="medium" preset="primary" @click="isCreateModalVisible = true">Create</VaButton>
          </div>
        </div>

        <ScriptsTable
          :scripts="scripts"
          :params="params"
          :pagination="pagination"
          @editScript="handleEditVisible"
          @delete="handleDelete"
          @execute="handleExecute"
        ></ScriptsTable>
      </VaCardContent>
    </VaCard>

    <VaModal v-model="isCreateModalVisible" hide-default-actions class="w-full h-full" size="large" no-esc-dismiss no-outside-dismiss>
      <CreateModal :visible="isCreateModalVisible" @save="handleCreate" @close="handleBeforeClose('create')" />
    </VaModal>

    <VaModal v-model="isEditModalVisible" hide-default-actions class="h-full" size="auto" no-esc-dismiss no-outside-dismiss>
      <EditModal :visible="isEditModalVisible" :new-script="selectedScript" @save="handleEdit" @close="handleBeforeClose('edit')" />
    </VaModal>

    <VaModal v-model="isExecuteModalVisible" size="auto" no-esc-dismiss no-outside-dismiss hide-default-actions close-button>
      <ExecuteAgentModal :visible="isExecuteModalVisible" :selected-script="selectedScript" />
    </VaModal>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue'
import { useToast } from 'vuestic-ui/web-components'
import { useScripts } from './composables/useScripts'
import { Script } from './composables/typeScripts'
import ScriptsTable from './widgets/ScriptsTable.vue'
import CreateModal from './modals/CreateModal.vue'
import EditModal from './modals/EditModal.vue'
import ExecuteAgentModal from './modals/ExecuteAgentModal.vue'
import { debounce } from 'lodash'
import ScriptImportExportWidget from '../monitic-settings/widgets/ScriptImportExportWidget.vue'

const { scripts, pagination, params, fetchScripts } = useScripts()
const { init: notify } = useToast()

const selectedScript = ref()
const searchInput = ref('')
const isCreateModalVisible = ref(false)
const isEditModalVisible = ref(false)
const isExecuteModalVisible = ref(false)

const debouncedSearch = debounce(() => {
  params.value.searchText = searchInput.value
}, 300)

watch(searchInput, () => {
  debouncedSearch()
})

function handleEditVisible(script: Script) {
  selectedScript.value = script
  isEditModalVisible.value = true
}

const handleExecute = async (script: Script) => {
  selectedScript.value = script
  isExecuteModalVisible.value = true
}

const handleCreate = async (script: Script) => {
  try {
    await useScripts().createScripts(script)
    notify({
      message: `${script.name} has been created.`,
      color: 'success',
    })
    isCreateModalVisible.value = false
    fetchScripts()
  } catch (error: any) {
    notify({
      message: `${error.response.data.error ?? 'Error'}: ${error.response.data.message ?? error.message}`,
      color: 'danger',
    })
  }
}

const handleEdit = async (script: Script, originalScript: Script) => {
  try {
    await useScripts().editScripts(script, originalScript)
    notify({
      message: `${originalScript.name} has been updated.`,
      color: 'success',
    })
    isEditModalVisible.value = false
    fetchScripts()
  } catch (error: any) {
    notify({
      message: `${error.response.data.error ?? 'Error'}: ${error.response.data.message ?? error.message}`,
      color: 'danger',
    })
  }
}

const handleDelete = async (script: Script) => {
  try {
    await useScripts().deleteScripts(script)
    notify({
      message: `${script.name} has been deleted.`,
      color: 'success',
    })
    fetchScripts()
  } catch (error: any) {
    notify({
      message: `${error.response.data.error ?? 'Error'}: ${error.response.data.message ?? error.message}`,
      color: 'danger',
    })
  }
}

const handleBeforeClose = (modalType: string) => {
  if (window.confirm('Are you sure you want to close the modal?')) {
    if (modalType == 'edit') {
      isEditModalVisible.value = false
    } else {
      isCreateModalVisible.value = false
    }
  }
}

onMounted(() => {
  fetchScripts()
})
</script>
