<template>
  <div ref="editorContainer" class="editor-container h-full w-full"></div>
</template>

<script setup lang="ts">
import { onMounted, ref, onBeforeUnmount, watch } from 'vue'
import * as monaco from 'monaco-editor'

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  language: {
    type: String,
    default: 'powershell',
  },
  options: {
    type: Object,
    default: () => ({}),
  },
})

const emit = defineEmits<{
  (event: 'update:modelValue', addon: string): void
  (event: 'update:editor', editor: monaco.editor.IStandaloneCodeEditor | null): void
}>()

const editorContainer = ref<HTMLDivElement | null>(null)
let editor: monaco.editor.IStandaloneCodeEditor | null = null

watch(
  () => props.modelValue,
  (newValue) => {
    if (editor && newValue !== editor.getValue()) {
      editor.setValue(newValue || '')
    }
  },
)

const handleClick = (params: string = '') => {
  const position = editor?.getPosition() || new monaco.Position(1, 1)
  const text = editor?.getValue(position as any)
  const splitedText = text?.split('\n') || ['']
  const lineContent = (splitedText as any)[(position?.lineNumber || 0) - 1]
  const textToInsert = `{{${params}}}`
  splitedText[position.lineNumber - 1] = [lineContent.slice(0, position.column - 1), textToInsert, lineContent.slice(position.column - 1)].join('')
  editor?.setValue(splitedText.join('\n'))
  editor?.setPosition(position)
}

onMounted(async () => {
  try {
    if (editorContainer.value) {
      editor = monaco.editor.create(editorContainer.value, {
        value: props.modelValue || '',
        language: props.language,
        theme: 'vs-dark',
        ...props.options,
        lineDecorationsWidth: 0,
        lineNumbersMinChars: 0,
        stickyScroll: {
          enabled: false,
        },
      })

      editor.onDidChangeModelContent(() => {
        if (editor) {
          const value = editor.getValue()
          emit('update:modelValue', value)
        }
      })

      const model = editor.getModel()
      if (model) {
        emit('update:editor', editor)
      } else {
        editor.onDidChangeModel(() => {
          emit('update:editor', editor)
        })
      }
    }
  } catch (error) {
    console.log(error)
  }
})

onBeforeUnmount(() => {
  editor?.dispose()
})

defineExpose({
  handleClick,
})
</script>

<style scoped>
.editor-container {
  min-height: 40vh;
  width: 100%;
}
</style>
