import { useToast } from 'vuestic-ui/web-components'
import { ExecutionStatus } from '../pages/dynamic-scripts/composables/typeExecutionStatus'
import * as crypto from 'crypto'
import { Md5 } from 'ts-md5'

export const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export async function sha256(message: string) {
  const msgBuffer = new TextEncoder().encode(message)
  const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => ('00' + b.toString(16)).slice(-2)).join('')
  return hashHex
}
export const md5 = (msg: string) => {
  return Md5.hashStr(msg)
}

export const uuid = (len: number, charSet: string = '') => {
  charSet = charSet || 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'
  var randomString = ''
  for (var i = 0; i < len; i++) {
    var randomPoz = Math.floor(Math.random() * charSet.length)
    randomString += charSet.substring(randomPoz, randomPoz + 1)
  }
  return randomString
}

/** Validation */
export const validators = {
  email: (v: string) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return pattern.test(v) || 'Please enter a valid email address'
  },
  url: (v: string) => {
    const pattern = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi
    return pattern.test(v) || 'Please enter a valid URL.'
  },
  required: (v: any) => (v === undefined || v === null || v === '' ? 'This field is required' : true),
  atLeast8Char: (v: any) => v.length >= 8 || 'This field can be a minimum of 8 characters.',
  noSpecialChars: (v: string) => {
    const pattern = /^[A-Za-z0-9]+$/
    return pattern.test(v) || 'Only English letters and numbers are allowed. No spaces or special characters.'
  },
  atMax60Val: (v: any) => (parseInt(v) <= 60 && parseInt(v) > -1) || 'This field can have values ​​between 1 and 60',
  atMax3600Val: (v: any) => (parseInt(v) <= 3600 && parseInt(v) > -1) || 'This field can have values ​​between 1 and 3600',
}

const toast = useToast()

export const copyToClipboard = (text: string) => {
  if (text == '' || text == undefined || text == null) {
    toast.init({ message: `Empty value!`, color: 'warning', duration: 1500 })
    return
  }
  const dummy = document.createElement('p')
  dummy.style.display = 'flex'
  dummy.id = 'dummy_id'
  dummy.innerText = text
  document.body.appendChild(dummy)
  const range = document.createRange()
  range.selectNodeContents(dummy)
  const selection = window.getSelection() ?? new Selection()
  selection.removeAllRanges()
  selection.addRange(range)
  try {
    document.execCommand('copy')
    toast.init({ message: `Copied!`, color: 'primary', duration: 1500 })
  } catch (err) {
    console.error('Unable to copy text', err)
    toast.init({ message: `Not Copied! ` + err, color: 'danger', duration: 1500 })
  }
  document.body.removeChild(dummy)
  selection.removeAllRanges()
}

export const getStatusColor = (result: ExecutionStatus) => {
  try {
    if (result.status && result.response.task.is_ok && result.response.task.is_done) {
      return '!bg-green-500'
    } else if (result.status && result.response.task.is_ok && !result.response.task.is_done) {
      return '!bg-yellow-500'
    } else if ((result.status && !result.response.task.is_ok) || result.response.task.is_done) {
      return '!bg-yellow-500'
    } else {
      return '!bg-red-500'
    }
  } catch (error) {
    return '!bg-red-500'
  }
}

export const getOsImageSrc = (osName: string) => {
  if (osName === 'windows') {
    return '/images/colored/windows-colored.png'
  } else if (osName === 'linux') {
    return '/images/colored/linux-colored.png'
  } else if (osName === 'macos') {
    return '/images/colored/macos-colored.png'
  } else {
    return '/images/colored/default.png'
  }
}

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}
