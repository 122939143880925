<template>
  <VaButton size="small" color="info" name="imp_exp_button" @click="openImportExportModal">Import/Export Scripts</VaButton>

  <section>
    <VaModal v-model="isModalVisible" title="Import/Export Scripts" hide-default-actions>
      <VaTabs v-model="selectedTab" vertical grow>
        <template #tabs>
          <VaTab v-for="tab in TABS" :key="tab.title" :name="tab.title">
            <VaIcon :name="tab.icon" size="small" class="mr-2" />
            {{ tab.title }}
          </VaTab>
        </template>

        <VaCard square outlined>
          <VaCardTitle>
            <VaIcon :name="currentTab?.icon" size="small" class="mr-2" color="background-element" />
            {{ currentTab?.title }}
          </VaCardTitle>
          <VaCardContent v-if="selectedTab === 'Export'">
            <div class="flex flex-col gap-4">
              <div class="relative w-full h-full">
                <div class="absolute top-0 right-0 m-2 z-10">
                  <VaButton class="me-1" icon="mso-clear_all" color="danger" size="small" @click="exportedData.b64 = ''" />
                  <VaButton class="" icon="mso-content_copy" size="small" @click="copyToClipboard(exportedData.b64)" />
                </div>
                <VaTextarea v-model="exportedData.b64" class="overflow-auto p-1 w-full" readonly />
              </div>
              <VaButton :loading="isExporting" class="self-start" size="small" @click="handleExport">Export Scripts</VaButton>
            </div>
          </VaCardContent>
          <VaCardContent v-else-if="selectedTab === 'Import'">
            <VaForm v-slot="{ isValid }" ref="importForm" class="flex flex-col gap-4">
              <VaTextarea v-model="importData.b64" rows="10" placeholder="Paste your import data here" :rules="[validators.required]"></VaTextarea>
              <VaButton :disabled="!isValid" :loading="isImporting" class="self-start" size="small" @click="handleImport">Import Scripts</VaButton>
            </VaForm>
          </VaCardContent>
        </VaCard>
      </VaTabs>

      <div class="flex gap-2 flex-col-reverse items-stretch w-full sm:flex-row sm:items-center mt-4">
        <div class="flex justify-end flex-1 gap-2">
          <VaButton preset="secondary" color="primary" @click="closeModal">Cancel</VaButton>
        </div>
      </div>
    </VaModal>
  </section>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { useToast, VaTabs } from 'vuestic-ui'
import axiosInstance from '../../../services/interceptor'
import { copyToClipboard, validators } from '../../../services/utils'

type DynamicScriptsExpImpType = {
  b64: string
}
const { init: notify } = useToast()
const isModalVisible = ref(false)
const TABS = [
  { icon: 'upload', title: 'Export', content: 'Export scripts' },
  { icon: 'download', title: 'Import', content: 'Import scripts' },
]
const currentTab = computed(() => {
  return TABS.find(({ title }) => title === selectedTab.value)
})
const selectedTab = ref(TABS[0].title)
const exportedData = ref<DynamicScriptsExpImpType>({ b64: '' })
const importData = ref<DynamicScriptsExpImpType>({ b64: '' })
const isExporting = ref(false)
const isImporting = ref(false)

const openImportExportModal = () => {
  isModalVisible.value = true
}

const closeModal = () => {
  isModalVisible.value = false
  exportedData.value.b64 = ''
  importData.value.b64 = ''
}

const handleExport = async () => {
  isExporting.value = true
  try {
    const response = await axiosInstance.get('/scripts/export')
    exportedData.value = (response.data as DynamicScriptsExpImpType) ?? { b64: '' }
    notify({ color: 'success', message: 'Scripts exported successfully' })
  } catch (error) {
    console.error('Export failed:', error)
    notify({ color: 'danger', message: 'Failed to export scripts' })
  } finally {
    isExporting.value = false
  }
}

const handleImport = async () => {
  if (!importData.value.b64) {
    notify({ color: 'warning', message: 'Please enter data to import' })
    return
  }

  isImporting.value = true
  try {
    await axiosInstance.post('/scripts/import', importData.value)
    notify({ color: 'success', message: 'Scripts imported successfully' })
  } catch (error) {
    console.error('Import failed:', error)
    notify({ color: 'danger', message: 'Failed to import scripts' })
  } finally {
    isImporting.value = false
  }
}
</script>
