import { ref, watch } from 'vue'
import { useToast } from 'vuestic-ui/web-components'
import axiosInstance from '../../../../services/interceptor'
import { UserLicense } from './typeUserLicense'
import { Agent } from '../../../agents/composables/typeAgents'
import { SelectableOption } from 'vuestic-ui/dist/types/composables'
import { User } from '../../users/composables/typeUsers'

export const useUserLicenses = () => {
  const userLicenses = ref<UserLicense[]>([])
  const isLoading = ref(false)
  const { init: notify } = useToast()

  const pagination = ref({
    current_page: 1,
    total: 1,
    total_pages: 1,
  })

  const params = ref<Record<string, SelectableOption>>({
    sort_by: 'status' as string | null,
    sort: 'desc' as 'asc' | 'desc' | null,
    current_page: 1,
    limit: 10,
  })

  const fetchUserLicenses = async (userId: string, oParams: object = {}) => {
    isLoading.value = true
    try {
      const p = {
        ...params.value,
        ...oParams,
      }
      const filteredParams = Object.fromEntries(Object.entries(p).filter(([_, v]) => v !== null && v !== undefined))
      var response = await axiosInstance.get('/user-licenses?user_id=' + userId + '&' + new URLSearchParams(filteredParams as any))
      userLicenses.value = response.data
      params.value.current_page = response.data.current_page
      pagination.value.total = response.data.total
      pagination.value.total_pages = response.data.total_pages

      return response.data.data as UserLicense[]
    } catch (error: any) {
      notify({
        message: `Error: ${error.message}`,
        color: 'danger',
      })
    } finally {
      isLoading.value = false
    }
  }

  const createUserLicense = async (userLicense: UserLicense) => {
    try {
      return (await axiosInstance.post(`/user-licenses`, userLicense)).data
    } catch (error) {
      throw error
    }
  }

  const deleteUserLicense = async (userLicense: any) => {
    try {
      return (await axiosInstance.delete(`/user-licenses/${userLicense.user_id}/${userLicense._id}`)).data
    } catch (error) {
      throw error
    }
  }

  const fetchAssignedOrUnassignedAgents = async (user_id: string, license_id: string, type = 'agents', searchKey = '') => {
    return (await axiosInstance.get(`/user-licenses/${user_id}/${license_id}/${type}`, { params: { search: searchKey } })).data.data as Agent[]
  }

  const removeAgentFromUserLicense = async (user_id: string, license_id: string, agent_ids: string) => {
    try {
      const body = {
        agent_id: agent_ids,
      }
      return (await axiosInstance.delete(`/user-licenses/${user_id}/${license_id}/agents`, { data: body })).data
    } catch (error) {
      throw error
    }
  }

  const addAgentFromUserLicense = async (user_id: string, license_id: string, agent_ids: string) => {
    try {
      const body = {
        agent_id: agent_ids,
      }
      return (await axiosInstance.post(`/user-licenses/${user_id}/${license_id}/agents`, body)).data
    } catch (error: any) {
      throw error
    }
  }

  const fetchAssignedOrUnassignedSubAccounts = async (user_id: string, license_id: string, type = 'sub-users') => {
    return (await axiosInstance.get(`/user-licenses/${user_id}/${license_id}/${type}`)).data.data as User[]
  }

  const removeSubUserFromUserLicense = async (user_id: string, license_id: string, sub_account_id: string) => {
    try {
      const body = {
        sub_account_id: sub_account_id,
      }
      return (await axiosInstance.delete(`/user-licenses/${user_id}/${license_id}/sub-accounts`, { data: body })).data
    } catch (error) {
      throw error
    }
  }

  const addSubUserFromUserLicense = async (user_id: string, license_id: string, sub_account_id: string) => {
    try {
      const body = {
        sub_account_id: sub_account_id,
      }
      return (await axiosInstance.post(`/user-licenses/${user_id}/${license_id}/sub-accounts`, body)).data
    } catch (error: any) {
      throw error
    }
  }

  const fetchUserSubAccounts = async (user_id: string) => {
    return (await axiosInstance.get(`/user`, { params: { parent: user_id } })).data.data as User[]
  }

  return {
    userLicenses,
    isLoading,
    pagination,
    params,
    fetchUserLicenses,
    fetchUserSubAccounts,
    createUserLicense,
    deleteUserLicense,
    fetchAssignedOrUnassignedAgents,
    removeAgentFromUserLicense,
    addAgentFromUserLicense,
    fetchAssignedOrUnassignedSubAccounts,
    removeSubUserFromUserLicense,
    addSubUserFromUserLicense,
  }
}
