<template>
  <div class="flex w-full md:w-1/5 border rounded-md bg-white">
    <div class="flex flex-col m-1 w-full gap-1">
      <VaTabs v-model="localSelectedTab" class="flex w-full items-center align-middle justify-center mb-1 mt-1">
        <template #tabs>
          <VaTab v-for="tab in ['Licenses']" :key="tab">
            {{ tab }}
          </VaTab>
        </template>
      </VaTabs>
      <div class="h-full overflow-auto">
        <div
          v-for="(uLicense, key) in filteredLicenses"
          :key="key"
          class="flex flex-row gap-2 bg-white p-2 border-b-2 hover:bg-blue-200 cursor-pointer justify-between"
          :class="getRowBg(uLicense)"
          @click="handleSelectRow(uLicense)"
        >
          <div v-if="uLicense.type == UserLicenseType.License" class="flex flex-col">
            <p>{{ uLicense.parentLicense.name }}</p>
            <span class="text-xs font-thin">{{ uLicense.parentLicense.description }} </span>
          </div>

          <div v-else class="flex flex-col">
            <p>{{ uLicense.parentAddon.name }}</p>
            <span class="text-xs font-thin">{{ uLicense.parentAddon.description }} </span>
          </div>

          <div v-if="uLicense.type == UserLicenseType.License" class="flex items-center">
            <label class="text-xs font-thin from-neutral-600">{{ uLicense.userLicense.used_agent_limit }}/{{ uLicense.parentLicense.agent_limit }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { UserLicenseModel, UserLicenseType } from '../composables/typeUserLicense'

const props = defineProps<{
  userLicenses: UserLicenseModel[]
  selectedTab: number
  selectedUserLicense: UserLicenseModel
}>()

const emit = defineEmits<{
  (e: 'selectLicense', license: UserLicenseModel): void
  (e: 'changeTab', tab: number): void
}>()

const localSelectedTab = computed({
  get: () => props.selectedTab,
  set: (value) => emit('changeTab', value),
})

const filteredLicenses = computed(() => {
  return props.userLicenses.filter((uLicense) =>
    localSelectedTab.value === 0 ? uLicense.type === UserLicenseType.License : uLicense.type === UserLicenseType.Addon,
  )
})

const getRowBg = (uLicense: UserLicenseModel) => {
  if (uLicense.userLicense.is_deleted && uLicense === props.selectedUserLicense) {
    return ['!bg-red-300', '!hover:bg-slate-700', '!hover:text-slate-300', '!cursor-not-allowed']
  } else if (uLicense.userLicense.is_deleted && uLicense !== props.selectedUserLicense) {
    return ['!bg-red-300', '!hover:bg-slate-700', '!hover:text-slate-300', '!cursor-not-allowed']
  } else if (!uLicense.userLicense.is_deleted && uLicense === props.selectedUserLicense) {
    return '!bg-blue-300'
  }
}

const handleSelectRow = (uLicense: UserLicenseModel) => {
  if (!uLicense.userLicense.is_deleted) {
    emit('selectLicense', uLicense)
  }
}
</script>
