<template>
  <div v-if="selectedUserLicense.userLicense && selectedUserLicense.userLicense._id" class="flex flex-row gap-2 h-full overflow-auto">
    <div class="flex flex-col w-full p-4 bg-white border rounded-md h-full">
      <UserAgents :user-licenses="selectedUserLicense"></UserAgents>
    </div>

    <div class="flex flex-col w-full p-4 bg-white border rounded-md h-full">
      <UserSubAccounts :user-licenses="selectedUserLicense"></UserSubAccounts>
    </div>
  </div>

  <div v-else class="flex flex-row gap-2 h-full overflow-auto">
    <div class="flex flex-col w-full p-4 bg-white border rounded-md h-full">
      <label class="text-[#154ec1] uppercase mb-4" style="font-size: 9px; font-weight: 700; line-height: 14px; letter-spacing: 0.4px; min-height: 14px">
        All Agents
      </label>
      <div class="overflow-auto p-2 h-full">
        <div v-for="(agent, key) in allUserAgents" :key="key" class="flex flex-col mb-2">
          <div class="flex flex-row gap-2 justify-between items-center">
            <img fit="contain" class="w-8" :src="getOsImageSrc(agent.os)" />
            <div class="flex flex-col w-full">
              <label>{{ agent.name ?? '' }}</label>
              <p class="text-xs font-thin">{{ agent.basic_data.hostname }}</p>
            </div>
            <div class="text-xs text-gray-500">Actions</div>
          </div>
          <VaDivider></VaDivider>
        </div>
        <div v-if="!allUserAgents.length" class="w-full h-full flex justify-center items-center">
          <NoDataSvg />
        </div>
      </div>
    </div>

    <div class="flex flex-col w-full p-4 bg-white border rounded-md h-full">
      <label class="text-[#154ec1] uppercase mb-4" style="font-size: 9px; font-weight: 700; line-height: 14px; letter-spacing: 0.4px; min-height: 14px">
        All Sub Accounts
      </label>
      <div class="overflow-auto p-2 h-full">
        <div v-for="(subAccount, key) in allUserSubAccounts" :key="key" class="flex flex-col mb-2">
          <div class="flex flex-row gap-2 justify-between items-center">
            <VaIcon name="account_circle" />
            <div class="flex flex-col w-full">
              <label>{{ subAccount.first_name }} {{ subAccount.last_name }}</label>
              <p class="text-xs font-thin">{{ subAccount.email }}</p>
            </div>
            <div class="text-xs text-gray-500">Actions</div>
          </div>
          <VaDivider></VaDivider>
        </div>
        <div v-if="allUserSubAccounts && !allUserSubAccounts.length" class="w-full h-full flex justify-center items-center">
          <NoDataSvg />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { UserLicenseModel } from '../composables/typeUserLicense'
import UserAgents from './UserAgents.vue'
import UserSubAccounts from './UserSubAccounts.vue'
import { getOsImageSrc } from '../../../../services/utils'
import NoDataSvg from '../../../../components/svgs/no-data-svg.vue'
// import { useAgents } from '../../../agents/composables/useAgents'
import { Agent } from '../../../agents/composables/typeAgents'
import { User } from '../../users/composables/typeUsers'
import { useUserLicenses } from '../composables/useUserLicenses'

const props = defineProps<{
  selectedUserLicense: UserLicenseModel
  selectedUser: User
}>()

// const { fet } = useAgents()
const { fetchUserSubAccounts } = useUserLicenses()

const allUserAgents = ref<Agent[]>([])
const allUserSubAccounts = ref<User[]>([])

const fetchUserData = async (userId: string) => {
  if (userId) {
    // allUserAgents.value = await fetchAgentsByUserId(userId)
    allUserSubAccounts.value = await fetchUserSubAccounts(userId)
  }
}

watch(
  () => props.selectedUser,
  (newUser) => {
    if (newUser && newUser.id) {
      fetchUserData(newUser.id)
    }
  },
  { immediate: true },
)
</script>
