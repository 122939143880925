import { ref, watch } from 'vue'
import axiosInstance from '../../../services/interceptor'
import { Script } from './typeScripts'
import { Agent } from '../../agents/composables/typeAgents'
import axios from 'axios'
import { SelectableOption } from 'vuestic-ui/dist/types/composables'
import { Pagination } from '../../../data/typePagination'

export const useScripts = () => {
  const scripts = ref<Script[]>([])
  const isLoading = ref(false)
  const pagination = ref<Pagination>({
    current_page: 1,
    limit: 6,
    total: 1,
    total_pages: 1,
  })

  const params = ref<Record<string, SelectableOption>>({
    sort_by: 'name' as string | null,
    sort: 'desc' as 'asc' | 'desc' | null,
    limit: 6,
    page: 1,
  })

  const fetchScripts = async (oParams: object = {}) => {
    try {
      const p = {
        ...params.value,
        ...oParams,
      }
      const filteredParams = Object.fromEntries(Object.entries(p).filter(([_, v]) => v !== null && v !== undefined && v !== ''))
      var response = await axiosInstance.get('/scripts', { params: filteredParams })
      scripts.value = response.data.data ?? ([] as Script[])

      params.value.page = response.data.current_page
      pagination.value.current_page = response.data.current_page
      pagination.value.total = response.data.total
      pagination.value.total_pages = response.data.total_pages

      if (response.data.totalPages < response.data.page) {
        params.value.page = response.data.totalPages
      }

      return scripts.value
    } catch (error) {
      throw error
    }
  }

  const createScripts = async (script: Script) => {
    try {
      const response = await axiosInstance.post('/scripts', script)
      return response.data.data
    } catch (error) {
      throw error
    }
  }

  const editScripts = async (script: Script, originalScript: Script) => {
    try {
      const response = await axiosInstance.patch(`/scripts/${originalScript._id}`, script)
      return response.data.data
    } catch (error) {
      throw error
    }
  }

  const deleteScripts = async (script: Script) => {
    try {
      const response = await axiosInstance.delete(`/scripts/${script._id}`)
      return response.data.data
    } catch (error) {
      throw error
    }
  }

  const executeScriptBySelectedAgent = async (script: Script, agent: Agent, params: Record<string, string> = {}, timeout: string) => {
    try {
      const response = await axiosInstance.post(`/scripts/execute/${script.name}/${agent._id}?timeout=${timeout}`, params, {
        timeout: (parseInt(timeout) + 10) * 1000, // Timeout değerini milisaniyeye çeviriyoruz ve 10 saniye ekliyoruz
      })
      return response.data
    } catch (error) {
      throw error
    }
  }

  watch(
    params,
    async () => {
      await fetchScripts(params.value)
    },
    { deep: true },
  )

  return {
    scripts,
    isLoading,
    params,
    pagination,
    fetchScripts,
    createScripts,
    editScripts,
    deleteScripts,
    executeScriptBySelectedAgent,
  }
}
