<template>
  <div>
    <h1 class="page-title">Users</h1>

    <VaCard>
      <VaCardContent>
        <div class="flex flex-col md:flex-row gap-2 mb-2 justify-between">
          <div class="flex flex-col md:flex-row gap-2 justify-start">
            <VaInput ref="searchInput" placeholder="Search" @keyup.enter="search()">
              <template #prependInner>
                <VaIcon name="search" color="secondary" size="small" />
              </template>
            </VaInput>
          </div>
        </div>

        <UsersTable :users="users" :params="params" :loading="isLoading" :pagination="pagination" @verifyUser="handleVerify" @detailUser="handleDetailUser" />
      </VaCardContent>
    </VaCard>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useUsers } from './composables/useUsers'
import UsersTable from './widgets/UsersTable.vue'
import { User } from './composables/typeUsers'
import { useToast } from 'vuestic-ui/web-components'
import router from '../../../router'

const { init: notify } = useToast()
const { users, isLoading, pagination, params, fetchUsers, verifyUser } = useUsers()

const searchInput = ref()

const handleDetailUser = (user: User) => {
  router.push({ name: 'user-detail', params: { user_id: user.id } })
}

const handleVerify = async (user: User) => {
  try {
    await verifyUser(user)
    notify({
      message: `${user.email} has been verified.`,
      color: 'success',
    })
  } catch (error: any) {
    notify({
      message: `Error: ${error.message}`,
      color: 'danger',
    })
  }
}

const search = () => {
  const searchText = searchInput.value?.$el.querySelector('input').value
  params.value.text_search = searchText
}

onMounted(() => {
  fetchUsers()
})
</script>
