import { ref, watch } from 'vue'
import { User } from './typeUsers'
import { useToast } from 'vuestic-ui/web-components'
import axiosInstance from '../../../../services/interceptor'
import { SelectableOption } from 'vuestic-ui/dist/types/composables'
import { Pagination } from '../../../../data/typePagination'

export const useUsers = () => {
  const users = ref<User[]>([])
  const isLoading = ref(false)
  const { init: notify } = useToast()

  const params = ref({
    sort_by: 'name' as string | 'name',
    sort: 'asc' as 'asc' | 'desc' | null,
    page: 1,
    limit: 10,
    text_search: null as string | null,
  })

  const pagination = ref<Pagination>({
    current_page: 1,
    total: 0,
    total_pages: 1,
    limit: 10,
  })

  const fetchUsers = async (oParams: object = {}) => {
    isLoading.value = true
    try {
      const p = {
        ...params.value,
        ...oParams,
      }

      const response = await axiosInstance.get('/user', { params: p })
      users.value = !response.data.data ? ([] as User[]) : response.data.data

      pagination.value.total = response.data.total
      pagination.value.total_pages = response.data.total_pages
      pagination.value.current_page = response.data.current_page

      return response.data.data ?? ([] as User[])
    } catch (error: any) {
      notify({
        message: `Error: ${error.message}`,
        color: 'danger',
      })
    } finally {
      isLoading.value = false
    }
  }

  const getUserById = async (user_id: string) => {
    var response = await axiosInstance.get(`/user/${user_id}`)
    return response.data as User
  }

  const verifyUser = async (user: User) => {
    isLoading.value = true

    try {
      await axiosInstance.post('verify-user', { email: user.email })
    } catch (error: any) {
      console.log('verify api caz yaptı', error)
      notify({
        message: `Error verifying user: ${error.message}`,
        color: 'danger',
      })
    } finally {
      isLoading.value = false
    }
  }

  const resetPassword = async (user: User) => {
    try {
      var data = {
        email: user.email,
      }
      const response = await axiosInstance.post('/change-password', data)
      return response.data.data
    } catch (error) {
      throw error
    }
  }

  watch(
    params,
    async () => {
      await fetchUsers(params.value)
    },
    { deep: true },
  )

  return {
    users,
    isLoading,
    params,
    pagination,
    fetchUsers,
    verifyUser,
    getUserById,
    resetPassword,
  }
}
